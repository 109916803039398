export const errorMessages = {
  accountTypeRequired: 'Account type is required.',
  emailRequired: 'Email address is required.',
  emailInvalid: 'Please enter a valid email address.',
  emailsDoNotMatch: 'Email addresses must match.',
  filesWereRejected: 'file(s) were rejected because of unsupported formats.',
  firstNameRequired: 'First name is required.',
  fileTitleRequired: 'File title is required.',
  folderTitleRequired: 'A folder title is required.',
  lastNameRequired: 'Last name is required.',
  loginInvalid: "We're sorry, the email and password does not match in our system.",
  passwordNotLongEnough: 'Password must contain at least 6 letters, numbers and symbols.',
  passwordsDoNotMatch: 'Passwords must match.',
  passwordMustContainNumbers: 'Password must contain at least one number.',
  passwordMustContainSpecialCharacters: 'Password must contain at least on special character.',
  passwordMustContainerUpperCaseLetters: 'Password must container at least one capital letter.',
  passwordRequired: 'Password is required.',
  phoneIsRequired: 'Phone number is required',
  phoneInvalid: 'Phone number must be in the format x-xxx-xxx-xxxx.',
  problemCreatingFolder: 'There was a problem creating that folder, please try again.',
  problemCopyingFiles: 'There was a problem copying those files, please try again.',
  problemDeletingFiles: 'There was a problem deleting the selected files, please try again.',
  problemDeletingUser: 'There was a problem deleting that user, please try again',
  problemDownloadingFiles: 'There was a problem downloading those files, please try again.',
  problemGettingFileAccess: 'There was a problem getting access to those files, please try again.',
  problemMovingFiles: 'There was a problem updating the selected file(s), please try again.',
  problemRetrievingUsers: 'There was a problem when trying to get users for this account. Please try again.',
  problemSharingFile: 'There was a problem retrieving the sharable url for that file. Please try again.',
  problemUpdatingUser: 'There was a problem updating the user details, please try again.',
  problemUploadingFiles: 'There was a problem uploading files. Please try again.',
  problemUploadingSomeFiles: 'file were not uploaded because of unkown issues. If the issue persists, please contact support.',
  subscriptionRequired: 'Subscription type is required.',
  UsernameExistsException: 'We\'re sorry but a user with that email already exists.'
};

export const successMessages = {
  downloadLinkSent: 'A link will be sent to your email address when your download is ready.',
  filesCopiedSuccesfully: 'File are being copied and will appear in the directory when they are ready. You may need to refresh the page to view them.',
  filesDeletedSuccesfully: 'Files were deleted successfully.',
  filesUpdatedSuccesfully: 'Files were updated successfully.',
  filesUploadedSuccessfully: 'Files were uploaded successfully.',
  folderCreatedSuccessfully: 'Folder was created.',
  passwordChangedSuccesfully: 'Your password was updated. Please login with your new password.',
  recoverySuccess: 'If that email address is associated with an account, you will receive an email link to reset your password.',
  userAdded: 'User was successfully added.',
  userAccessSuccessfullyAdded: 'Access to the user(s) was successfully added.',
  userDeleted: 'User was successfully deleted',
  userUpdated: 'User was successfully updated'
};

import React, { useState } from 'react';
import { Button, Form, FormControl, Modal } from 'react-bootstrap';
import { Plus } from 'react-bootstrap-icons';

import { errorMessages } from '../../../shared/constants/messages';

export default ({ show, toggleModal, addFolder }: any) => {
  const [ title, setTitle ] = useState('');
  const [ titleError, setTitleError ] = useState('');
  const [ description, setDescription ] = useState('');

  const submitForm = (e: any) => {
    e.preventDefault();
    if (!title) {
      setTitleError(errorMessages.folderTitleRequired);
      return;
    }
    addFolder(title, description);
    setTitle('');
    setDescription('');
  };

  return (
    <Modal show={show} onHide={() => toggleModal('showAddFolderModal')}>
      <Modal.Header closeButton>
        <Modal.Title>Add New Folder</Modal.Title>    
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="title">
            <Form.Label>Folder Title</Form.Label>
            <Form.Control
              type="text" size="lg" required isInvalid={!!titleError} autoComplete="off" aria-autocomplete="none"
              onChange={e => setTitle(e.target.value)}
            />
            <FormControl.Feedback type="invalid">{ titleError }</FormControl.Feedback>
          </Form.Group>
          <Form.Group controlId="description">
            <Form.Label>Folder Description</Form.Label>
            <Form.Control
              type="text" size="lg" required value={description} autoComplete="off" aria-autocomplete="none"
              onChange={e => setDescription(e.target.value)}
            />
          </Form.Group>
          <Button
           type="submit" variant="primary" size="lg" block
            disabled={false} onClick={submitForm}
          >
            <Plus size="25" />
            Add Folder
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

import React from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { Arrow90degUp, ExclamationCircle, Grid3x3GapFill, List, Plus, Upload } from 'react-bootstrap-icons';
import { API } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';

import HeirloomTooltip from '../../../shared/components/tooltip/HeirloomTooltip.component';
import './FileManager.component.scss';
import { checkFile, getFiles, setAllUnchecked, sortFiles, setAllChecked } from '../../../redux/files/files.actions';
import { toastsAdd } from '../../../redux/messaging/messaging.actions';
import PageHeader from '../pageHeader/PageHeader.component';
import GridView from './views/GridView.component';
import ListView from './views/ListView.component';
import AddFolderModal from '../modals/AddFolderModal.component';
import HeirloomUploadFiles from '../modals/UploadFilesModal.component';
import FileViewer from '../../fileViewer/FileViewer.component';

import SidebarComponent from './sidebar/Sidebar.component';
import { connect } from 'react-redux';
import AppLoadingScreenComponent from '../../../shared/components/appLoadingScreen/AppLoadingScreen.component';
import { errorMessages, successMessages } from '../../../shared/constants/messages';
import MoveFilesModal from '../modals/MoveFilesModal.component';
import RenameFilesModal from '../modals/RenameFilesModal.component';
import CopyFilesModal from '../modals/CopyFilesModal.component';
import ShareFileModal from '../modals/ShareFileModal.component';
import AddAccessModal from '../modals/AddAccessModal.component';
import { lvl3Permissions, lvl2Permissions, lvl1Permissions } from './fileManager.constants';

const mapStateToProps = (state: any) => ({ // @todo typing
  files: state.files,
  user: state.user
});

const mapDispatchToProps = {
  checkFile,
  getFiles,
  setAllUnchecked,
  setAllChecked,
  toastsAdd,
  sortFiles
}

class FileManager extends React.Component<any> {
  state: any;

  constructor(props: any) {
    super(props);
    this.state = {
      currentSearchValue: '',
      currentDirectory: { file_id: '.' },
      currentFile: null,
      directoryHierarchy: [ { file_id: '.', name: 'Home' } ],
      generatedShareUrl: null,
      isSelfLoading: false,
      listFormat: 'grid',
      addAccessModal: false,
      showAddFolderModal: false,
      showDropFileModal: false,
      showMoveFilesModal: false,
      showRenameFilesModal: false,
      showCopyFilesModal: false,
      showShareFileModal: false,
      viewingTrash: false,
      userAccessList: null,
      selectedFiles: null,
      showFilesOfType: '*'
    };
  }

  componentDidMount() {
    document.addEventListener('keyup', this.handleDocumentKeypress);
    this.props.getFiles();
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.handleDocumentKeypress);
  }

  render() {
    const { isLoading } = this.props.files;
    const { isSelfLoading } = this.state;
    const permissions = this.getActionPermissions();
    return (
      <React.Fragment>
        { (isSelfLoading || isLoading) && <AppLoadingScreenComponent /> }
        <Container fluid className="file-manager">
          <Row className="file-manager__wrapper align-items-stretch">
            <Col lg="9" className="file-manager__file-list-wrapper">
              <PageHeader 
                showSearch={true}
                title={this.state.directoryHierarchy}
                searchSubmit={this.searchSubmit}
                navigateFolders={this.navigateFolders}
              />
              <div className="file-manager__file-controls file-controls d-xl-flex justify-content-between">
                <div className="file-controls__buttons">
                  { this.getButtons(permissions) }
                </div>
                <div className="file-controls__view-selection">
                  <button onClick={() => this.updateView('grid')} >
                    <Grid3x3GapFill size="20" /> Grid
                  </button>
                  <button onClick={() => this.updateView('list')} >
                    <List size="20"/> List
                  </button>
                  <div className="file-manager__tooltip">
                    <HeirloomTooltip placement="top" label="Help">
                      Click a file or folder to select it.
                      Double click a file or folder to view its contents.
                      Click this link to view the Heirloom Cloud Help Page.
                    </HeirloomTooltip>
                  </div>
                </div>
              </div>
              {
                !this.state.currentFile &&
                  <div className="file-manager__check-files-links">
                    <a href="#" onClick={(e) => {
                      e.preventDefault();
                      this.setAllFilesChecked();
                    }}>Select All</a>
                    <a href="#" onClick={(e) => {
                      e.preventDefault();
                      this.setAllUnchecked();
                    }}>Unselect All</a>
                  </div>
              }
              { this.state.currentFile
                ? <FileViewer file={this.state.currentFile} />
                : <div className="file-manager__file-list">
                    { this.getFileList() }
                  </div>
              }
            </Col>
            <Col lg="3" className="file-manager__sidebar sidebar">
              <SidebarComponent
                currentFile={this.state.currentFile}
                currentDirectory={this.state.currentDirectory}
                downloadFiles={this.downloadFiles}
                files={this.props.files}
                getUserFileAccess={this.getUserFileAccess}
                updateFiles={(restoring = false, deleting = false) =>
                  this.updateFiles(this.state.currentDirectory.file_id, this.state.currentSearchValue, restoring, deleting)}
                toggleModal={this.toggleModal}
                share={this.getSharedFileUrl}
                selectedFiles={this.props.files.selectedFiles}
                getSharedContent={this.getSharedContent}
                toggleTrash={this.toggleTrash}
                isViewingTrash={this.state.viewingTrash}
                permissions={permissions}
              />
            </Col>
          </Row>
        </Container>
        <HeirloomUploadFiles
          currentDirectory={this.state.currentDirectory.file_id}
          show={this.state.showDropFileModal}
          toggleModal={() => this.toggleModal('showDropFileModal')}
          finishUpload={this.finishUpload}
          userId={this.props.user.creds.attributes.sub}
        />
        <AddFolderModal
          show={this.state.showAddFolderModal}
          toggleModal={() => this.toggleModal('showAddFolderModal')}
          addFolder={this.addFolder}
          isLoading={this.state.isSelfLoading}
        />
        <MoveFilesModal
          fileList={this.props.files.fileList}
          currentDirectory={this.state.currentDirectory}
          show={this.state.showMoveFilesModal}
          toggleModal={() => this.toggleModal('showMoveFilesModal')}
          submitForm={this.updateFiles}
        />
        <RenameFilesModal
          show={this.state.showRenameFilesModal}
          toggleModal={() => this.toggleModal('showRenameFilesModal')}
          files={this.props.files.fileList}
          saveFile={this.updateFiles}
          isLoading={this.state.isSelfLoading}
          selectedFile={this.state.currentFile}
        />
        <CopyFilesModal
          fileList={this.props.files.fileList}
          currentDirectory={this.state.currentDirectory}
          show={this.state.showCopyFilesModal}
          toggleModal={() => this.toggleModal('showCopyFilesModal')}
          submitForm={this.copyFiles}
        />
        <ShareFileModal
          show={this.state.showShareFileModal}
          fileUrl={this.state.generatedShareUrl}
          toggleModal={() => {
            this.setState({
              showShareFileModal: false,
              generatedShareUrl: ''
            });
          }}
        />
        <AddAccessModal
          show={this.state.addAccessModal}
          toggleModal={() => this.toggleModal('addAccessModal')}
          userList={this.state.userAccessList}
          user={this.props.user}
          selectedFiles={this.props.files.selectedFiles}
          updateUserAccessList={this.updateUserAccessList}
          toastsAdd={this.props.toastsAdd}
          fullAccessList={this.state.fullAccessList}
        />
      </React.Fragment>
    );
  }

  getActionPermissions = () => {
    const { currentDirectory, currentFile } = this.state;
    const { selectedFiles } = this.props.files;
    let permissionsObject: any;
    let leastSelectedFilesPermission = 3;
    if (currentDirectory.access_level < leastSelectedFilesPermission) {
      leastSelectedFilesPermission = currentDirectory.access_level;
    } else if (currentFile && currentFile.access_level < leastSelectedFilesPermission) {
      leastSelectedFilesPermission = currentFile.access_level;
    } else {
      selectedFiles.forEach((file: any) => {
        if (file.isShared && file.access_level < leastSelectedFilesPermission) {
          leastSelectedFilesPermission = file.access_level;
        }
      });
    }

    if (leastSelectedFilesPermission === 3) {
      permissionsObject = lvl3Permissions;
    } else if (leastSelectedFilesPermission === 2) {
      permissionsObject = lvl2Permissions;
    } else {
      permissionsObject = lvl1Permissions;
    }
    return permissionsObject;
  }

  getUserFileAccess = async () => {
    this.setState({ isSelfLoading: true });
    try {
      let selectedFiles = [];
      if (this.state.currentFile) {
        selectedFiles.push(this.state.currentFile.file_id);
      } else {
        selectedFiles = this.props.files.selectedFiles.map((selectedFile: any) => selectedFile.file_id);
      }
      const bucket = await API.get('API_GATEWAY', '/user-interaction', {
        queryStringParameters: {
          file_ids: selectedFiles.join(',')
        }
      });
      if (bucket.error || bucket.message !== 'Success.') {
        this.props.toastsAdd({
          type: 'error',
          message: bucket.message
        });
      } else {
        this.setUserAccessList(bucket.payload, selectedFiles);
        this.setState({ isSelfLoading: false });
        this.toggleModal('addAccessModal');
      }
    } catch (e) {
      this.props.toastsAdd({
        type: 'error',
        message: e.message
      });
      this.setState({ isSelfLoading: false });
      this.hideAllModals();
      throw(e);
    }
  }

  setUserAccessList = (accessList: any, selectedFiles: any[]) => {
    const userDupcCeck = new Set();
    const userAccessList: any = [];
    accessList.forEach(({ access_status, user_id, user_email }: any) => {
      if (!userDupcCeck.has(user_id)) {
        const accessLength = accessList.filter(
          (accessFile: any) => accessFile.user_id === user_id && accessFile.access_status !== access_status).length;
        userDupcCeck.add(user_id);
        userAccessList.push({
          access_status: accessLength > 0 ? 'varies' : access_status,
          user_id,
          user_email
        });
      }
    });
    this.setState({
      fullAccessList: accessList,
      userAccessList,
      selectedFiles
    });
  }

  addFolder = async (name: string, description: string) => {
    try {
      const user_id = this.props.user.creds.attributes.sub;
      this.setState({ isSelfLoading: true });
      const bucket = await API.put('API_GATEWAY', '/files', {
        body: {
          payload: [{
            file_id: uuidv4(),
            parent_dir_id: this.state.currentDirectory.file_id,
            name,
            content_type: 'directory',
            user_id,
            description,
            is_in_trash: false,
            upload_part_sizes: []
          }]
        }
      });
      if (bucket.error || bucket.message !== 'Success.') {
        this.props.toastsAdd({
          type: 'error',
          message: errorMessages.problemCreatingFolder
        });
      } else {
        this.props.toastsAdd({
          type: 'success',
          message: successMessages.folderCreatedSuccessfully
        });
      }
      this.setState({ isSelfLoading: false });
      this.hideAllModals();
      this.props.getFiles();
    } catch (e) {
      this.props.toastsAdd({
        type: 'error',
        message: errorMessages.problemCreatingFolder
      });
      this.setState({ isSelfLoading: false });
      this.hideAllModals();
      this.props.getFiles();
      throw(e);
    }
  }

  copyFiles = async (directory: any) => {
    try {
      this.setState({ isSelfLoading: true });
      const filesToCopy: any[] = [];
      if (this.state.currentFile) {
        const { currentFile } = this.state;
        filesToCopy.push({
          file_id: currentFile.file_id,
          parent_dir_id: directory,
          content_type: currentFile.content_type,
          is_in_trash: currentFile.is_in_trash
        });
      } else {
        this.props.files.fileList.forEach((file: any) => {
          if (file.checked) {
            filesToCopy.push({
              file_id: file.file_id,
              parent_dir_id: directory,
              content_type: file.content_type,
              is_in_trash: file.is_in_trash
            });
          }
        });
      }
      const bucket: any = API.post('API_GATEWAY', '/copy-files', {
        body: {
          payload: filesToCopy
        }
      });
      this.setState({ isSelfLoading: true });
      setTimeout(() => {
        this.props.toastsAdd({
          type: 'success',
          message: successMessages.filesCopiedSuccesfully
        });
        this.setState({ isSelfLoading: false });
        this.hideAllModals();
      }, 1000);
    } catch(e) {
      this.setState({ isSelfLoading: false });
      this.hideAllModals();
      this.props.toastsAdd({
        type: 'error',
        message: errorMessages.problemCopyingFiles
      });
    }
  }

  getButtons = (permissions: any) => {
    if (this.state.viewingTrash) {
      return (
        <React.Fragment>
          { ((this.state.currentDirectory.file_id && this.state.currentDirectory.file_id !== '.') || this.state.currentFile) && 
            <Button onClick={() => this.moveUpDirectory()} variant="success">
              <Arrow90degUp /> Back
            </Button>
          }
          <Button
            variant="danger"
            onClick={() => this.deleteFiles()}
            disabled={this.props.files.numChecked < 1 || permissions.delete.disabled}
            title={permissions.delete.message}
          >
            <ExclamationCircle /> Delete Permanently
          </Button>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          { ((this.state.currentDirectory.file_id && this.state.currentDirectory.file_id !== '.') || this.state.currentFile) && 
            <Button onClick={() => this.moveUpDirectory()} variant="success">
              <Arrow90degUp /> Back
            </Button>
          }
          <Button onClick={() => this.toggleModal('showDropFileModal')} disabled={permissions.uploadFiles.disabled} title={permissions.uploadFiles.message}>
            <Upload /> Upload Files
          </Button>
          <Button onClick={() => this.toggleModal('showAddFolderModal')} disabled={permissions.newFolder.disabled} title={permissions.newFolder.message}>
            <Plus size="22" /> New Folder
          </Button>
          <Form.Group controlId="show_files" className="file-manager__show-files">
            <Form.Control as="select" 
              value={this.state.showFilesOfType}
              onChange={e => this.setState({ showFilesOfType: e.target.value })}>
                <option value="*">All</option>
                <option value="owned">Owned by me</option>
                <option value="shared">Shared with me</option>
            </Form.Control>
          </Form.Group>
        </React.Fragment>
      );
    }
  }

  handleDocumentKeypress = (e: any) => {
    if (e.keyCode === 46) {
      if (this.state.isViewingTrash) {
        this.deleteFiles();
      } else {
        this.updateFiles(this.state.currentDirectory.file_id, null, false, true);
      }
    }
  }

  deleteFiles = async () => {
    try {
      this.setState({ isSelfLoading: true });
      const { fileList } = this.props.files;
      const filesToDelete: any[] = [];
      if (this.state.currentFile) {
        filesToDelete.push({ file_id: this.state.currentFile.file_id });
        this.setState({
          currentFile: null
        });
      } else {
        fileList.forEach((file: any) => {
          if (file.checked) {
            filesToDelete.push({ file_id: file.file_id });
          }
        });
      }
      const bucket = await API.del('API_GATEWAY', '/files', {
        body: {
          payload: filesToDelete
        }
      });
      if (bucket.error || bucket.message !== 'Success.') {
        this.props.toastsAdd({
          type: 'error',
          message: errorMessages.problemDeletingFiles
        });
      } else {
        this.props.toastsAdd({
          type: 'success',
          message: successMessages.filesDeletedSuccesfully
        });
        this.setState(
          { isSelfLoading: false },
          () => this.props.getFiles() 
        );
      }
    } catch (e) {
      this.props.toastsAdd({
        type: 'error',
        message: errorMessages.problemDeletingFiles
      });
      this.setState({ isSelfLoading: false });
      this.props.getFiles();
      throw(e);
    }
  }

  downloadFiles = async () => {
    this.setState({ isSelfLoading: true });
    const { fileList } = this.props.files;
    const filesToDownload: any[] = [];
    if (this.state.currentFile) {
      filesToDownload.push({ file_id: this.state.currentFile.file_id });
    } else {
      fileList.forEach((file: any) => {
        if (file.checked) {
          filesToDownload.push({ file_id: file.file_id });
        }
      })
    }
    API.post('API_GATEWAY', '/zip-download', {
      body: {
        payload: filesToDownload
      }
    });
    setTimeout(() => {
      this.props.toastsAdd({
        type: 'success',
        message: successMessages.downloadLinkSent
      });
      this.setState({ isSelfLoading: false });
    }, 2500);
    // if (bucket.error || bucket.message !== 'Success.') {
    //   this.props.toastsAdd({
    //     type: 'error',
    //     message: bucket.error || errorMessages.problemDownloadingFiles
    //   });
    // } else {
      
    //   const link = document.createElement('a');
    //   link.download = 'Heirloom-Cloud-Download.zip';
    //   link.href = bucket.url;
    //   document.body.appendChild(link);
    //   link.click();
    //   document.body.removeChild(link);
    // }
  }

  getSharedFileUrl = async () => {
    try {
      this.setState({ isSelfLoading: true });
      const file = this.state.currentFile || this.props.files.fileList.find((file: any) => file.checked);
      const bucket = await API.get('API_GATEWAY', '/share-file', {
        queryStringParameters: {
          file_id: file.file_id
        }
      });
      if (bucket.error || bucket.message !== 'Success.') {
        this.props.toastsAdd({
          type: 'error',
          message: errorMessages.problemSharingFile
        });
        this.setState({
          generatedUrl: null,
          isSelfLoading: false
        });
      } else {
        return bucket;
        // const { name, content_type, description } = file;
        // const urlParam = btoa(`${url}{--}${name}{--}${description || ''}{--}${content_type}`);
        // let generatedUrl = `http://localhost:3000/guest/${urlParam}`;
        // this.setState({
        //   generatedShareUrl: generatedUrl,
        //   isSelfLoading: false,
        //   showShareFileModal: true
        // });
      }
    } catch(e) {
      this.setState({
        isSelfLoading: false,
        generatedUrl: null
      });
      this.props.toastsAdd({
        type: 'error',
        message: errorMessages.problemSharingFile
      });
      throw(e);
    }
  }

  getSharedContent = async (shared_link_id: string) => {
    try {
      this.setState({ isSelfLoading: true });
      const bucket = await API.get('API_GATEWAY', '/shared-link-files', {
        queryStringParameters: {
          shared_link_id
        }
      });
      this.setState({ isSelfLoading: false });
      return bucket;
    } catch(e) {
      this.setState({
        isSelfLoading: false
      });
      this.props.toastsAdd({
        type: 'error',
        message: e.message
      });
      throw(e);
    }
  }

  getUpdatedTrashStatus = (file: any, restoring: boolean, deleting: boolean) => {
    if (restoring) {
      return false;
    }
    return deleting || this.state.viewingTrash || file.is_in_trash || false;
  }

  setAllFilesChecked = () => this.props.setAllChecked(this.state.currentDirectory.file_id);

  setAllUnchecked = () => this.props.setAllUnchecked();

  updateFiles = async (directoryId: string, file?: any, restoring = false, deleting = false) => {
    this.setState({ isSelfLoading: true });
    try {
      const user_id = this.props.user.creds.attributes.sub;
      const { fileList } = this.props.files;
      const { currentFile } = this.state;
      const directory = fileList.find((file: any) => file.file_id === directoryId);
      let updatedFiles: any[];
      if (file) {
        updatedFiles = [{
          file_id: file.file_id,
          name: file.name,
          description: file.description,
          parent_dir_id: (restoring || deleting) ? '.' : file.parent_dir_id,
          user_id,
          is_in_trash: this.getUpdatedTrashStatus(file, restoring, deleting)
        }];
      } else {
        if (currentFile) {
          updatedFiles = [{
            file_id: currentFile.file_id,
            parent_dir_id: (restoring || deleting) ? '.' : directory ? directory.file_id : '.',
            name: currentFile.name,
            user_id,
            description: currentFile.description || '',
            is_in_trash: this.getUpdatedTrashStatus(currentFile, restoring, deleting)
          }];
          this.setState({
            currentFile: null
          });
        } else {
          const { selectedFiles } = this.props.files;
          updatedFiles = selectedFiles.map((file: any) => ({
            file_id: file.file_id,
            parent_dir_id: directory ? directory.file_id : '.',
            name: file.name,
            user_id,
            description: file.description || '',
            is_in_trash: this.getUpdatedTrashStatus(file, restoring, deleting)
          }));
        }
      }
      const bucket = await API.patch('API_GATEWAY', '/files', {
        body: {
          payload: updatedFiles
        }
      });
      if (bucket.error || bucket.message !== 'Success.') {
        this.props.toastsAdd({
          type: 'error',
          message: errorMessages.problemMovingFiles
        });
      } else {
        if (currentFile && file  && file.parent_dir_id === currentFile.parent_dir_id) {
          this.setState({
            currentFile: {
              ...this.state.currentFile,
              displayName: file.name,
              description: file.description
            }
          });
        } else if (currentFile && file && file.parent_dir_id !== currentFile.parent_dir_id) {
          this.setState({ currentFile: null });
          this.navigateFolders(null, 0);
        }
        this.props.toastsAdd({
          type: 'success',
          message: successMessages.filesUpdatedSuccesfully
        });
        this.props.getFiles();
        this.props.setAllUnchecked();
      }
      this.hideAllModals();
      this.setState({ isSelfLoading: false });
    } catch (e) {
      this.hideAllModals();
      this.props.toastsAdd({
        type: 'error',
        message: errorMessages.problemMovingFiles
      });
      this.props.getFiles();
      this.setState({ isSelfLoading: false });
      throw (e);
    }
  }

  // setChildFilesToParent = (fileList: any[], parentDirectoryId: string, deleting: boolean) => {
  //   fileList.forEach((file: any) => {
  //     if (file.parent_dir_id === parentDirectoryId) {
  //       file.is_in_trash = deleting;
  //       if (file.content_type === 'directory') {
  //         this.setChildFilesToParent(fileList, file.file_id, deleting);
  //       } else {
  //         const parentDirectory = fileList.find((subFile: any) => file.parent_dir_id === subFile.file_id);
  //         if (!deleting)
  //       }
  //     }
  //   });
  // }

  finishUpload = () => {
    this.props.setAllUnchecked();
    this.props.getFiles();
    this.toggleModal('showDropFileModal')
  }

  getFileList = () => {
    if (this.props.files.fileList && this.props.files.fileList.length) {
      if (this.state.listFormat === 'grid') {
        return (
          <GridView
            currentSearchValue={this.state.currentSearchValue}
            currentDirectory={this.state.currentDirectory.file_id}
            fileList={this.props.files.fileList}
            selectFile={this.selectFile}
            viewFile={this.viewFile}
            isViewingTrash={this.state.viewingTrash}
            showFilesOfType={this.state.showFilesOfType}
          />
        );
      } else {
        return (
          <ListView
            currentSearchValue={this.state.currentSearchValue}
            currentDirectory={this.state.currentDirectory.file_id}
            fileList={this.props.files.fileList}
            selectFile={this.selectFile}
            viewFile={this.viewFile}
            isViewingTrash={this.state.viewingTrash}
            showFilesOfType={this.state.showFilesOfType}
            sortFiles={this.props.sortFiles}
            sortOptions={this.props.files.sortOptions}
          />
        );
      }
    } else {
      return <p>You have no files yet. Click the upload files button above to get started!</p>;
    }
  }

  searchSubmit = (currentSearchValue: string) => this.setState({ currentSearchValue })

  selectFile = (e: any, file: any): void => {
    if (!file) {
      return;
    }
    if (e.keyCode) {
      if (e.keyCode !== 32 && e.keyCode !== 13) {
        return;
      }
      if (e.keyCode === 13) {
        this.viewFile(file);
        return;
      }
    }
    this.props.checkFile(file.file_id)
    this.getActionPermissions();
  }

  moveUpDirectory = () => {
    const { directoryHierarchy } = this.state;
    directoryHierarchy.pop();
    if (this.state.currentFile) {
      this.setState({
        currentFile: null,
        directoryHierarchy
      });
    } else {
      this.setAllUnchecked();
      let currentDirectory = this.props.files.fileList.find((file: any) =>
        this.state.currentDirectory.parent_dir_id === file.file_id);
      if (!currentDirectory) {
        currentDirectory = { file_id: '.' };
      }
      this.setState({
        currentDirectory,
        directoryHierarchy
      });
    }
  }

  navigateFolders = (e: any, index: number) => {
    const directoryHierarchy = [ ...this.state.directoryHierarchy ];
    const currentDirectory = directoryHierarchy[index];
    directoryHierarchy.splice(index + 1, directoryHierarchy.length - (index + 1));
    this.setState({
      currentDirectory,
      currentFile: null,
      directoryHierarchy
    });
  }

  

  viewFile = (file: any, e?: any) => {
    if (e) {
      e.nativeEvent.stopPropagation();
    }
    const { directoryHierarchy } = this.state;
    directoryHierarchy.push(file);
    this.props.setAllUnchecked();
    if (file.content_type === 'directory') {
      this.setState({
        currentDirectory: file,
        directoryHierarchy
      });
    } else {
      this.setState({
        currentFile: file,
        directoryHierarchy
      });
    }
    this.getActionPermissions();
  }

  toggleModal = (modal: string): void => this.setState({ [modal]: !this.state[modal] });

  toggleTrash = () => {
    const directoryHierarchy = [ { file_id: '.', name: 'Home' } ];
    this.setState({
      viewingTrash: !this.state.viewingTrash,
      currentDirectory: { file_id: '.' },
      currentFile: null,
      directoryHierarchy
    });
    this.props.setAllUnchecked();
  }

  hideAllModals = () => {
    this.setState({
      addAccessModal: false,
      showAddFolderModal: false,
      showDropFileModal: false,
      showMoveFilesModal: false,
      showRenameFilesModal: false,
      showCopyFilesModal: false,
      showShareFileModal: false
    });
  }

  updateView = (listFormat: string): void => {
    if (this.state.currentFile) {
      this.moveUpDirectory();
    }
    this.setState({ listFormat, currentFile: null });
  }

  updateUserAccessList = (userAccessList: any) => this.setState({ userAccessList });
}

export default connect(mapStateToProps, mapDispatchToProps)(FileManager);

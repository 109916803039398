import React from 'react';
import { withRouter } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';

import SignIn from './signin/SignIn.component';
import AccountRecovery from './accountRecovery/AccountRecovery.component';
import { userLogin, userSignup, userRecovery, submitPasswordChange, submitForgotPassword } from '../../redux/user/user.actions';
import { removeErrors } from '../../redux/messaging/messaging.actions';
import { emailRegex, capitalLettersRegex, numbersRegex, specialCharactersRegex } from '../../shared/constants/regex';
import { errorMessages } from '../../shared/constants/messages';
import logo from '../../shared/images/logo.svg';
import './Login.component.scss';
import ChangePassword from './changePassword/ChangePassword.component';

const mapStateToProps = (state: any) => ({ // @todo typing
  user: state.user
});

const mapDispatchToProps = {
  userLogin,
  userSignup,
  userRecovery,
  submitPasswordChange,
  removeErrors,
  submitForgotPassword
}

class Login extends React.Component<any> {
  deafaultState = {
    createAccountEmail: '',
    createAccountPassword: '',
    createAccountConfirmEmail: '',
    createAccountConfirmPassword: '',
    recoveryEmail: '',
    username: '',
    password: '',
    errors: {
      createAccountEmail: '',
      createAccountPassword: '',
      recoveryEmail: '',
      username: '',
      password: '',
      login: ''
    }
  };
  state: any = this.deafaultState;

  componentWillUnmount() {
    this.setState(this.deafaultState);
  }

  render() {
    return (
      <Container className="login-component" fluid>
        <Row>
          <Col lg="4" md="5" className="d-flex align-items-md-center justify-content-center order-1 order-sm-1 order-md-0 order-lg-0 order-xl-0">
            <div className="login-component__form-wrapper">
              <div className="login-component__logo">
                <img src={logo} alt="Heirloom Cloud Logo" />
              </div>
              <Switch>
                <Route exact path="/login">
                  <SignIn
                    formErrors={{ ...this.state.errors, login: this.props.user.error }}
                    isLoading={this.props.user.isLoading}
                    updateField={this.updateField}
                    submitLogin={this.submitLogin}
                  />
                </Route>
                <Route path="/login/forgot-password">
                  <AccountRecovery
                    formErrors={this.state.errors}
                    user={this.props.user}
                    updateField={this.updateField}
                    submitAccountRecovery={this.submitAccountRecovery}
                    recoveryEmail={this.state.recoveryEmail}
                  />
                </Route>
                <Route path="/login/change-password">
                  <ChangePassword
                    submitPasswordChange={this.submitPasswordChange}
                    user={this.props.user}
                    recoveryEmail={this.state.recoveryEmail}
                  />
                </Route>
                {/* <Route path="/login/create-account"> // @todo use this page in app V2
                  <CreateAccount
                    formErrors={this.state.errors}
                    isLoading={this.props.user.isLoading}
                    updateField={this.updateField}
                    submitCreateAccount={this.submitCreateAccount}
                    user={this.props.user}
                  />
                </Route> */}
              </Switch>
            </div>
          </Col>
          <Col lg="8" md="7" className="login-component__bg order-0 order-sm-0 order-md-1 order-lg-1 order-xl-1" />
        </Row>
      </Container>
    );
  }

  submitPasswordChange = (e: any, password: string, verificationCode: string, recoveryEmail: string) => {
    e.preventDefault();
    if (verificationCode && recoveryEmail) {
      this.props.submitForgotPassword(password, verificationCode, recoveryEmail);
    } else {
      this.props.submitPasswordChange(password);
    }
  }

  submitCreateAccount = (e: Event): void => {
    e.preventDefault();
    const {
      createAccountEmail,
      createAccountPassword,
      createAccountConfirmEmail,
      createAccountConfirmPassword
    } = this.state;
    let createAccountUsernameError = '';
    let createAccountPasswordError = '';
    if (createAccountEmail === createAccountConfirmEmail) {
      if (!emailRegex.test(createAccountEmail)) {
        createAccountUsernameError = errorMessages.emailInvalid;
      }
    } else {
      createAccountUsernameError = errorMessages.emailsDoNotMatch;
    }
    if (createAccountPassword.length > 6) {
      if (createAccountPassword !== createAccountConfirmPassword) {
        createAccountPasswordError = errorMessages.passwordsDoNotMatch;
      } else if (!numbersRegex.test(createAccountPassword)) {
        createAccountPasswordError = errorMessages.passwordMustContainNumbers;
      } else if (!capitalLettersRegex.test(createAccountPassword)) {
        createAccountPasswordError = errorMessages.passwordMustContainerUpperCaseLetters;
      } else if (!specialCharactersRegex.test(createAccountPassword)) {
        createAccountPasswordError = errorMessages.passwordMustContainSpecialCharacters;
      }
    } else {
      createAccountPasswordError = errorMessages.passwordNotLongEnough;
    }
    if (!createAccountUsernameError && !createAccountPasswordError) {
      this.props.userSignup({ username: createAccountEmail, password: createAccountPassword });
    }
    this.setState({
      errors: {
        ...this.state.errors,
        createAccountEmail: createAccountUsernameError,
        createAccountPassword: createAccountPasswordError
      }
    });
  }

  submitLogin = (e: Event): void => {
    e.preventDefault();
    const { username, password } = this.state;
    let usernameError = '';
    let passwordError = '';
    if (username) {
      if (!emailRegex.test(username)) {
        usernameError = errorMessages.emailInvalid;
      }
    } else {
      usernameError = errorMessages.emailRequired;
    }
    if (!password) {
      passwordError = errorMessages.passwordRequired;
    }
    if (!usernameError && !passwordError) {
      this.props.userLogin({ username, password });
    }
    this.setState({
      errors: {
        ...this.state.errors,
        username: usernameError,
        password: passwordError
      }
    });
  }

  submitAccountRecovery = (e: Event): void => {
    e.preventDefault();
    let recoveryEmailError = '';
    if (!this.state.recoveryEmail) {
      recoveryEmailError = errorMessages.emailRequired;
    } else if (!emailRegex.test(this.state.recoveryEmail)) {
      recoveryEmailError = errorMessages.emailInvalid;
    } else {
      this.props.userRecovery(this.state.recoveryEmail);
    }
    this.setState({
      errors: {
        ...this.state.errors,
        recoveryEmail: recoveryEmailError
      }
    });
  }

  updateField = (key: string, value: string): void => {
    this.setState({
      [key]: value
    });
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));

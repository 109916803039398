import React, { useRef, useState } from 'react';
import { Overlay, Tooltip } from 'react-bootstrap';

import './HeirloomTooltip.component.scss';

export default ({ height, width, placement, children, label }: any) => {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  height = height ? height : '24px';
  width = width ? width : '24px';
  placement = placement ? placement : 'left';

  return (
    <div className="heirloom-tooltip">
      <button 
        ref={target}
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
        onClick={() => window.open('https://heirloom.cloud/help', '_blank')}
        onFocus={() => setShow(true)}
        onBlur={() => setShow(false)}
      >
        <span style={{ height, width }} className="helper-icon">?</span> {label}
      </button>
      <Overlay target={target.current} show={show} placement={placement}>
        {(props) => (
          <Tooltip id="overlay-example" {...props}>
            { children }
          </Tooltip>
        )}
      </Overlay>
    </div>
  );
};

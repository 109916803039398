import React, { Component } from 'react';
import { Modal, ProgressBar } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';
import { API } from 'aws-amplify';
import axios from 'axios';
import { toastsAdd } from '../../../redux/messaging/messaging.actions';
import uploaderIcon from '../../../shared/images/uploader-icon.svg';
import { connect } from 'react-redux';
import { errorMessages, successMessages } from '../../../shared/constants/messages';

const mapDispatchToProps = {
  toastsAdd
}

class HeirloomUploadFiles extends Component<any> {
  state: any;

  constructor(props: any) {
    super(props);
    this.state = {
      isUploading: false,
      uploadProgress: 0,
      rejectedFiles: []
    };
  }

  render() {
    const { show, toggleModal } = this.props;
    return (
      <Modal
        show={show}
        className="file-manager__upload-files-modal"
        onHide={() => toggleModal('showDropFileModal')}
      >
        <Modal.Body>
          <button
            type="button"
            className="close custom-close"
            onClick={() => toggleModal('showDropFileModal')}
          >
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
          { this.getModalBody() }
        </Modal.Body>
      </Modal>
    );
  }

  getModalBody = () => {
    return (
      <React.Fragment>
        { this.state.isUploading  && 
          <div className="upload-files-modal__progress-bar">
            <h2>Uploading {this.state.uploadedFileCount} of {this.state.uploadFileCount} File(s)...</h2>
            <ProgressBar animated now={this.state.uploadProgress}/>
          </div>   
        }
        { !this.state.isUploading &&
          <Dropzone onDrop={this.handleFiles} accept=".png,.jfif,.jpg,.jpeg,.mp4,.mp3,mpeg,.mov,.wmv,.flv,.avi,.flac,.pdf,.docx,.doc,.ppt,.pptx,.tiff,.txt,.xls,.xlsx,.html" disabled={this.state.isUploading}>
            {({ isDragActive, getRootProps, getInputProps }) => (
              <section className="dropzone">
                <div {...getRootProps()} className={`dropzone__drop-area ${isDragActive ? 'dropzone__drop-area--active' : ''}`}>
                  <input {...getInputProps()} disabled={this.state.isUploading} />
                  <img src={uploaderIcon} />
                  <p>Drag and drop files and folders here, or click to select files. <br/>
                    Supported File Types: PNG, JFIF, JPG, JPEG, MP4, MP3, MOV, FLAC, PDF, DOCX, DOC, PPT, PPTX, TIFF, TXT, XLS, XLSX, HTML
                  </p>
                </div>
              </section>
            )}
          </Dropzone>
        }
      </React.Fragment>
    );
  }

  filesRequestHandler = async (files: any, retries = 5) => {
    try {
      const bucket = await API.put('API_GATEWAY', '/files', {
        body: {
          payload: files
        }
      });
      if (bucket.error || bucket.message !== 'Success.') {
        throw(bucket);
      } else {
        return bucket;
      }
    } catch (e) {
      if (retries === 0) {
        return e;
      } else {
        this.filesRequestHandler(files, --retries);
      }
    }
  }

  handleFiles = async (files: any, rejected: any) => {
    try {
      this.setState({
        isUploading: true,
        uploadProgress: 0,
        uploadFileCount: 0,
        uploadedFileCount: 0
      });
      const { finishUpload } = this.props;
      const newFolderSet = new Set();
      const newFolders: any[] = [];
      const newFiles: any[] = [];
      const errorList: any[] = [];
      const user_id = this.props.userId;
      let totalUploadBytes = 0;
      let totalBytesUploaded = 0;
      let folderBucket: any;
      let fileBucket: any;
      let closeTranserBucket: any;
      const fiveGigs = 5368709120;
      const uploadRequestHandler = async (file: any, uploadProps: any, index: number, retries = 5) => {
        let chunkBytesAlreadyLogged = 0;
        const axiosInstance = axios.create();
        delete axiosInstance.defaults.headers.put['Content-Type'];
        const url = uploadProps.urls[index];
        const uploadedFile = await axiosInstance.put(url, file, {
          onUploadProgress: (e) => {
            const currentChunkBytesUploaded = ((e.loaded / e.total) * file.size);
            const currentBytesToLog = currentChunkBytesUploaded - chunkBytesAlreadyLogged;
            chunkBytesAlreadyLogged = currentChunkBytesUploaded;
            totalBytesUploaded = currentBytesToLog + totalBytesUploaded;
            const uploadProgress = (totalBytesUploaded / totalUploadBytes) * 100;
            if (this.state.uploadProgress < uploadProgress) {
              this.setState({
                uploadProgress: (totalBytesUploaded / totalUploadBytes) * 100
              });
            }
          }
        });
        if (uploadedFile.headers['etag']) {
          return { etag: uploadedFile.headers['etag'], status: 'success' }
        } else {
          if (retries === 0) {
            throw({ message: 'Something went wrong', status: 'error' });
          } else {
            uploadRequestHandler(file, uploadProps, index, --retries);
          }
        }
      }
      files.forEach((file: any, index: number) => {
        const { name, path } = file;
        if (path.includes('/')) {
          const pathParts = path.split('/');
          pathParts.forEach((part: string, partIndex: number) => {
            if (part && part !== name && !newFolderSet.has(part)) {
              let parentFolderUuid = this.props.currentDirectory;
              if (newFolderSet.has(pathParts[partIndex - 1])) {
                parentFolderUuid = newFolders.find((folder: any) => folder.name === pathParts[partIndex - 1]).file_id;
              }
              newFolders.push({
                file_id: uuidv4(),
                parent_dir_id: parentFolderUuid,
                name: part,
                content_type: 'directory',
                user_id,
                description: '',
                in_in_trash: false,
                upload_part_sizes: []
              });
              newFolderSet.add(part);
            } else if (part && part === name) {
              const matchDirectory = newFolders.find((folder: any) => folder.name === pathParts[pathParts.length - 2]);
              const parent_dir_id = matchDirectory ? matchDirectory.file_id : '.';
              newFiles.push({
                file_id: uuidv4(),
                parent_dir_id,
                name,
                content_type: file.type,
                index,
                user_id,
                description: '',
                in_in_trash: false
              });
              totalUploadBytes = totalUploadBytes + file.size;
            }
          });
        } else {
          newFiles.push({
            file_id: uuidv4(),
            parent_dir_id: this.props.currentDirectory,
            name,
            content_type: file.type,
            index,
            user_id,
            description: '',
            in_in_trash: false
          });
          totalUploadBytes = totalUploadBytes + file.size;
        }
      });

      this.setState({
        totalBytesToUpload: totalUploadBytes,
        uploadFileCount: newFiles.length
      });

      if (newFolders.length) {
        // folders first
        for (let i = 0; i < newFolders.length; i++) {
          folderBucket = await this.filesRequestHandler( [ newFolders[i] ] );
          if (!folderBucket || folderBucket.error || folderBucket.message !== 'Success.') {
            this.props.toastsAdd({
              type: 'error',
              message: folderBucket ? folderBucket.message : errorMessages.problemUploadingFiles
            });
            return;
          }
        }
      }
      if (newFiles.length) {
        // files second
        if (this.state.uploadedFileCount === 0) {
          this.setState({ uploadedFileCount: 1 });
        }
        for (let i = 0; i < newFiles.length; i++) {
          const currentFile = newFiles[i];
          currentFile.upload_part_sizes = [];
          const chunks = [];
          const { size } = files[currentFile.index];
          const promises = [];
          let respParts: any;
          if (size > fiveGigs) {
              let startPointer = 0;
              const endPointer = size;
              while(startPointer < endPointer){
                const newStartPointer = startPointer + fiveGigs;
                chunks.push(files[currentFile.index].slice(startPointer , newStartPointer));
                if (newStartPointer > size) {
                  currentFile.upload_part_sizes.push(size - startPointer);
                } else {
                  currentFile.upload_part_sizes.push(fiveGigs);
                }
                startPointer = newStartPointer;
              }
          } else {
            chunks.push(new Blob([ files[currentFile.index] ]));
            currentFile.upload_part_sizes.push(size);
          }
          // fixes weird name bug
          if (currentFile.name.indexOf('.') === 0) {
            currentFile.name = currentFile.name.substring(1);
          }
          fileBucket = await this.filesRequestHandler([ currentFile ]);
          if (!fileBucket || fileBucket.error || fileBucket.message !== 'Success.') {
            errorList.push({ file: currentFile, error: fileBucket });
            continue;
          }
          try {
            for (let i = 0; i < currentFile.upload_part_sizes.length; i++) {            
              promises.push(uploadRequestHandler(chunks[i], { ...fileBucket.payload[0], size }, i));
            }
            respParts = await Promise.all(promises);
          } catch (e) {
            errorList.push({ file: currentFile, error: closeTranserBucket });
            continue;
          }
          const etags: any = [];
          const part_numbers: any = [];
          respParts.map((part: any, index: number) => {
            etags.push(part.etag.replace(/\"/g, ""));
            part_numbers.push(index + 1);
          });
          try {
            closeTranserBucket = await API.put('API_GATEWAY', '/files', {
              body: {
                payload: [{
                  file_id: currentFile.file_id,
                  etags,
                  part_numbers,
                  upload_id: fileBucket.payload[0].upload_id
                }]
              }
            });
          } catch (e) {
            errorList.push({ file: currentFile, error: closeTranserBucket });
            continue;
          }
          if (!closeTranserBucket || closeTranserBucket.error || closeTranserBucket.message !== 'Success.') {
            errorList.push({ file: currentFile, error: closeTranserBucket });
          }
          if (i !== (newFiles.length - 1)) {
            this.setState({
              uploadedFileCount: this.state.uploadedFileCount + 1
            });
          }
        }
      }
  
      this.setState({
        uploadProgress: 100
      });
      setTimeout(() => {
        this.setState({ 
          isUploading: false,
          uploadProgress: 0
        });
        if (errorList.length !== newFiles.length) {
          this.props.toastsAdd({
            type: 'success',
            message: successMessages.filesUploadedSuccessfully
          });
        }
        if (errorList.length === newFiles.length) {
          this.props.toastsAdd({
            type: 'error',
            message: errorMessages.problemUploadingFiles
          });
        } else if (errorList.length) {
          this.props.toastsAdd({
            type: 'error',
            message: `${errorList.length} ${errorMessages.problemUploadingSomeFiles}`
          });
        }
        if (rejected.length) {
          this.props.toastsAdd({
            type: 'error',
            message: `${rejected.length} ${errorMessages.filesWereRejected}`
          });
        }
        finishUpload();
      }, 3000);
    } catch (e) {
      this.setState({ 
        isUploading: false,
        uploadProgress: 0
      });
      throw(e);
    }
  }
}

export default connect(null, mapDispatchToProps)(HeirloomUploadFiles);

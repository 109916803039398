import React, { useState } from 'react';
import { Form, Table } from 'react-bootstrap';

import FileIconComponent from './FileIcon.component';

export default ({ currentSearchValue, currentDirectory, fileList, selectFile, viewFile, isViewingTrash, showFilesOfType }: any) => {
  const [ sort, setSort ] = useState({ column: 'name', direction: 'asc' });

  const getClassName = (column: string): string => {
    if (column === sort.column) {
      return sort.direction === 'asc' ? 'sorting_asc' : 'sorting_desc';
    }
    return 'sorting';
  }
  const setSortFunc = (column: string) => {
    if (column === sort.column) {
      setSort({ column, direction: sort.direction === 'asc' ? 'desc' : 'asc' });
    } else {
      setSort({ column, direction: 'asc' }); 
    }
  }
  return (
    <div className="file-manager__table-wrapper">
      <Table hover>
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th 
              className={getClassName('name')} onClick={() => setSortFunc('name')}>NAME</th>
            <th className={`file-type ${getClassName('type')}`} onClick={() => setSortFunc('type')}>TYPE</th>
            <th className={`last-modified ${getClassName('created')}`} onClick={() => setSortFunc('created')}>MODIFIED</th>
            <th className={`description ${getClassName('description')}`} onClick={() => setSortFunc('description')}>DESCRIPTION</th>
          </tr>
        </thead>
        <tbody>
          { fileList.length && fileList
            .filter((file: any) => {
              switch (showFilesOfType) {
                case 'owned':
                  if (file.isShared) {
                    return false;
                  }
                  break;
                case 'shared':
                  if (!file.isShared) {
                    return false;
                  }
                  break;
                case '*':
                default:
                  break;
              }
              if (isViewingTrash) {
                if (file.is_in_trash && (file.parent_dir_id === currentDirectory || (currentDirectory === '.' && currentSearchValue))) {
                  if (currentSearchValue) {
                    let match = false;
                    userLoop:
                    for (const prop in file) {
                      if (typeof file[prop] === 'string') {
                        if (file[prop].toLowerCase().includes(currentSearchValue.toLowerCase())) {
                          match = true;
                          break userLoop;
                        }
                      }
                    }
                    return match;
                  }
                  return true;
                }
                return false;
              } else if ((file.parent_dir_id === currentDirectory || (currentDirectory === '.' && currentSearchValue)) && !file.is_in_trash) {
                if (currentSearchValue) {
                  let match = false;
                  userLoop:
                  for (const prop in file) {
                    if (typeof file[prop] === 'string') {
                      if (file[prop].toLowerCase().includes(currentSearchValue.toLowerCase())) {
                        match = true;
                        break userLoop;
                      }
                    }
                  }
                  return match;
                }
                return true;
              }
              return false;
            })
            .sort((a: any, b: any) => {
              const multiplier = sort.direction === 'asc' ? 1 : -1;
              if (a[sort.column].toLowerCase() < b[sort.column].toLowerCase()) {
                return -1 * multiplier;
              }
              if (a[sort.column].toLowerCase() > b[sort.column].toLowerCase()) {
                return 1 * multiplier;
              }
              return 0;
            })
            .map((file: any) => {
              return (
                <tr
                  tabIndex={0}
                  id={file.file_id}
                  key={file.file_id}
                  onClick={e => selectFile(e, file)}
                  onDoubleClick={() => viewFile(file)}
                  onKeyUp={e => selectFile(e, file)}
                  title={file.hover_title}
                >
                  <td>
                    <Form.Group>
                      <Form.Check type="checkbox" tabIndex={-1} checked={file.checked} readOnly />
                    </Form.Group>
                  </td>
                  <td>
                    <div className="file-name">
                      <span className="file-manager__thumbnail-wrapper">
                        <FileIconComponent file={file} />
                      </span>
                      <span>{ file.displayName }</span>
                    </div>
                  </td>
                  <td className="file-type">{file.type}</td>
                  <td className="last-modified">{file.last_updated}</td>
                  <td>{file.description}</td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </div>
  );
};

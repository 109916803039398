import React, { useState } from 'react';
import { InputGroup, Modal, Form, Alert } from 'react-bootstrap';
import { Clipboard } from 'react-bootstrap-icons';

export default ({ show, toggleModal, fileUrl }: any) => {
  const [ isCopied, setIsCopied ] = useState(false);
  const input: any = React.useRef();

  const copyToClipboard = () => {
    const copyText: any = document.getElementById("generated-share-url");
    if (copyText) {
      try {
        copyText.select();
        copyText.setSelectionRange(0, 99999); /* For mobile devices */
        if (document.execCommand("copy")) {
          setIsCopied(true);
        }
      } catch(e) {
        throw(e);
      }
    }
  };

  return (
    <Modal
      show={show} onHide={() => {
        setIsCopied(false);
        toggleModal();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Share File</Modal.Title>    
      </Modal.Header>
      <Modal.Body>
        { isCopied &&
            <Alert variant="success">
              Link Copied successfully!
            </Alert>
        }
        <label htmlFor="generated-share-url" onClick={copyToClipboard}>Click to copy to clipboard</label>
        <InputGroup onClick={copyToClipboard} style={{cursor: "pointer"}}>
          <Form.Control type="text" value={fileUrl} ref={input} id="generated-share-url"  readOnly={true} />
            <InputGroup.Append>
              <InputGroup.Text><Clipboard /></InputGroup.Text>
            </InputGroup.Append>
        </InputGroup>
      </Modal.Body>
    </Modal>
  );
}

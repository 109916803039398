import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import { filesEpics } from './files/files.epics';
import { userEpics } from './user/user.epics';
import { User } from './user/user.reducer';
import { Files } from './files/files.reducer';
import { Messaging } from './messaging/messaging.reducer';

const rootEpic = combineEpics(
  ...filesEpics,
  ...userEpics
);

export const configureStore = () => {
  const composeEnhancer = composeWithDevTools({
    name: 'Heirloom Cloud'
  });
  const epicMiddleware = createEpicMiddleware<any>();
  const store = createStore(
    combineReducers({
      files: Files,
      messaging: Messaging,
      user: User
    }),
    composeEnhancer(applyMiddleware(epicMiddleware))
  );

  epicMiddleware.run(rootEpic);

  return store;
};

import React, { useState } from 'react';
import { Alert, Button, Form, FormControl, InputGroup, Spinner } from 'react-bootstrap';
import { EyeFill, EyeSlashFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";

export default ({ formErrors, isLoading, updateField, submitLogin }: any) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <React.Fragment>
      <h1>Sign In</h1>
      { formErrors.login ? <Alert variant="danger">{ formErrors.login }</Alert> : ''}
      <Form>
        <Form.Group controlId="email">
          <Form.Label>Email Address</Form.Label>
          <Form.Control
            type="email" size="lg" required isInvalid={!!formErrors.username} autoComplete="on"
            onChange={e => updateField('username', e.target.value)} disabled={isLoading}
          />
          <FormControl.Feedback type="invalid">{ formErrors.username }</FormControl.Feedback>
        </Form.Group>
        <div className="d-flex align-items-center justify-content-between">
          {/* <Form.Check
            className="login-component__remember"
            type="checkbox"
            id="remember"
            label="Remember Me"
          /> */}
          <div />
          <Link to='/login/forgot-password' className="login-component__forgot-password">
            Forgot Your Password?
          </Link>
        </div>
        <label htmlFor="password" className="login-component__password-label">Password</label>
        <InputGroup>
          <Form.Control
            autoComplete="off"
            type={showPassword ? 'text' : 'password'}
            size="lg"
            required
            isInvalid={!!formErrors.password}
            onChange={e => updateField('password', e.target.value)}
            disabled={isLoading}
          />
          <InputGroup.Append 
            className="account-details__password-show"
            onClick={() => setShowPassword(!showPassword)}>
            <InputGroup.Text>
              {
                !showPassword
                  ? <EyeSlashFill />
                  : <EyeFill />
              }
            </InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
        {
          !!formErrors.password &&
            <span className="invalid-feedback d-block">
              { formErrors.password }
            </span>
        }
        <Button type="submit" variant="primary" size="lg" block
          onClick={submitLogin} disabled={false} className="has-spinner"
        >
          Sign In
          { isLoading ?
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            /> : ''
          }
        </Button>
        {/* <Button type="submit" variant="secondary" size="lg" block className="login-component__create-account"
          onClick={() => history.push('/login/create-account')}
        >
          Create an Account
        </Button> */}
      </Form>
    </React.Fragment>
  );
}

import React, { Component } from 'react';
import { Alert, Button, Container } from 'react-bootstrap';
import { MDBDataTable } from 'mdbreact';
import { PlusCircle } from 'react-bootstrap-icons';
import PageHeader from '../pageHeader/PageHeader.component';
import { userDelete, userCreate, userGetUserList, userUpdate, clearUserList } from '../../../redux/user/user.actions';
import { connect } from 'react-redux';
import AccountDetails from './accountDetails/AccountDetails.component';
import HeirloomUploadFiles from '../modals/UploadFilesModal.component';
import AppLoadingScreenComponent from '../../../shared/components/appLoadingScreen/AppLoadingScreen.component';
import activeStatus from '../../../shared/images/status-active.svg';
import activeDeleted from '../../../shared/images/status-deactive.svg';
import suspendedStatus from '../../../shared/images/status-suspended.svg';

import './Account.component.scss';
import { formatBytes } from '../../../shared/util/formatBytes';

const mapStateToProps = (state: any) => ({ // @todo typing
  user: state.user
});

const mapDispatchToProps = {
  clearUserList,
  userDelete,
  userCreate,
  userGetUserList,
  userUpdate
}

class Account extends Component<any> {
  state: any;

  constructor(props: any) {
    super(props);
    this.state = {
      currentSearch: '',
      isEditing: false,
      showAccountStats: false,
      selectedUser: { },
      showUploadFilesModal: false,
      currentSort: '',
      currentSortDirection: 'desc'
    };
  }

  componentDidMount() {
    const { creds } = this.props.user;
    const accountType = creds.attributes && creds.attributes['custom:account_type'];
    const email = creds.attributes.email;
    this.props.userGetUserList(accountType === 'customer' ? email : '*');
    if (accountType === 'customer' && !this.state.showAccountStats) {
      this.setState({
        showAccountStats: true,
        isEditing: true
      });
    }
  }

  componentDidUpdate() {
    const { creds, userList } = this.props.user;
    const accountType = creds.attributes && creds.attributes['custom:account_type'];
    if (accountType === 'customer' && userList.length && (!this.state.selectedUser || !Object.keys(this.state.selectedUser).length)) {
      this.setState({
        selectedUser: userList[0]
      });
    }
  }

  componentWillUnmount() {
    const { creds } = this.props.user;
    const accountType = creds.attributes && creds.attributes['custom:account_type'];
    if (accountType === 'customer') {
      this.props.clearUserList();
    }
  }

  render() {
    const { isUserListLoading, creds } = this.props.user;
    if (isUserListLoading) {
      return <AppLoadingScreenComponent />;
    }
    return (
      <React.Fragment>
        <Container fluid>
          <div className="account-manager">
            <PageHeader showSearch={true} title="Account" searchSubmit={this.searchSubmit} searchPlaceholder="Search Users"/>
            { this.getCurrentAccountPage() }
          </div>
        </Container>
      </React.Fragment>
    );
  }

  getCurrentAccountPage = () => {
    const { creds, userListError, userList } = this.props.user;
    const accountType = creds.attributes && creds.attributes['custom:account_type'];
    const userSubscription = creds.attributes['custom:subscription'];
    if (this.state.showAccountStats && userList.length) {
      return (
        <React.Fragment>
          { this.showAccountDetails() &&
            <AccountDetails
              accountType={accountType}
              deleteUser={this.deleteUser}
              isEditing={this.state.isEditing}
              selectedUser={this.state.selectedUser}
              toggleAccountStats={this.toggleAccountStats}
              toggleFileUpload={this.toggleModal}
              saveUser={this.saveUser}
              userSubscription={userSubscription}
            />
          }
          <HeirloomUploadFiles
            currentDirectory={'.'}
            show={this.state.showUploadFilesModal}
            toggleModal={this.toggleModal}
            finishUpload={this.toggleModal}
            userId={this.state.selectedUser.user_id}
          />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Button className="account-manager__add-user" onClick={e => this.toggleAccountStats(e, false)}>
            <PlusCircle size="22" /> { accountType !== 'customer' ? 'Add User' : 'Add Family' }
          </Button>
          { userListError ? <Alert variant="danger" className="account-manager__error">{ userListError }</Alert> : ''}
          { this.getUserList() }
        </React.Fragment>
      )
    }
  }

  toggleModal = () => {
    this.setState({
      showUploadFilesModal: !this.state.showUploadFilesModal
    });
  }

  showAccountDetails = () => {
    const { isEditing, showAccountStats, selectedUser } = this.state;
    const { creds } = this.props.user;
    const accountType = creds.attributes && creds.attributes['custom:account_type'];
    if (accountType === 'customer' && selectedUser && Object.keys(selectedUser).length > 0) {
      return true;
    } else if (accountType !== 'customer') {
      if (!isEditing && showAccountStats) {
        return true;
      } else if (isEditing) {
        return true;
      }
    }
    return false;
  }

  getUserList = () => {
    const { userList } = this.props.user;

    if (userList && userList.length) {
      const data: any = {};
      data.columns = [
        {
          label: 'STATUS',
          field: 'status',
          width: '100px'
        },
        {
          label: 'NAME',
          field: 'name',
          sort: 'asc'
        },
        {
          label: 'ACCOUNT',
          field: 'email',
          sort: 'asc'
        },
        {
          label: 'CREATED',
          field: 'created',
          sort: 'asc'
        },
        {
          label: 'OWNERS CREATED',
          field: 'customer_accounts_created',
          sort: 'asc'
        },
        {
          label: 'TOTAL FILES',
          field: 'files_added',
          sort: 'asc'
        },
        {
          label: 'SIZE ADDED',
          field: 'size_added',
          sort: 'asc'
        },
        {
          label: 'EDIT',
          field: 'edit'
        }
      ];
      data.rows = userList.filter((user: any) => {
        if (this.state.currentSearch) {
          let match = false;
          userLoop:
          for (const prop in user) {
            if (typeof user[prop] === 'string') {
              if (user[prop].toLowerCase().includes(this.state.currentSearch.toLowerCase())) {
                match = true;
                break userLoop;
              }
            }
          }
          return match;
        }
        return true;
      }).map((user: any) => {
        let size = 0;
        if (user.family_storage_used == undefined) {
          const familyAdmin = userList.find((userInList: any) => user.family_patriarch_id === userInList.user_id);
          if (familyAdmin) {
            size = familyAdmin.family_storage_used;
          } else {
            size = user.size_added;
          }
        } else {
          size = user.family_storage_used;
        }
        return {
          status: this.getStatus(user),
          size_added: formatBytes(size),
          name: `${user.given_name} ${user.family_name}`,
          email: user.email,
          created: user.created,
          customer_accounts_created: user.customer_accounts_created,
          files_added: user.files_added,
          edit: <Button onClick={e => this.toggleAccountStats(e, true, user)}>EDIT</Button>
        }
      });

      return (
        <MDBDataTable striped bordered data={data} className="account-manager__user-table" searching={false}>
        </MDBDataTable>
      )
    };

    // return (
    //   <div className="account-manager__user-table-wrapper">
    //     <Table hover className="account-manager__user-table">
    //       <thead>
    //         <tr>
    //           <th>STATUS</th>
    //           <th>NAME</th>
    //           <th>ACCOUNT</th>
    //           <th>CREATED</th>
    //           {/* <th>LAST LOG IN</th>
    //           <th>HOURS</th> */}
    //           <th>OWNERS CREATED</th>
    //           <th>FILES ADDED</th>
    //           <th>SIZE ADDED</th>
    //         </tr>
    //       </thead>
    //       <tbody>
    //         { userList.length
    //           ? userList
    //             .filter((user: any) => {
    //               if (this.state.currentSearch) {
    //                 let match = false;
    //                 userLoop:
    //                 for (const prop in user) {
    //                   if (typeof user[prop] === 'string') {
    //                     if (user[prop].toLowerCase().includes(this.state.currentSearch.toLowerCase())) {
    //                       match = true;
    //                       break userLoop;
    //                     }
    //                   }
    //                 }
    //                 return match;
    //               }
    //               return true;
    //             })
    //             .map((user: any, index: number) => {
    //               totalOwners = totalOwners + user.customer_accounts_created;
    //               totalFilesAdded = totalFilesAdded + user.files_added;
    //               totalSizeAdded = totalSizeAdded + user.size_added;
    //               if (user) {
    //                 const totalGb = +(((user.size_added || 0) / 1000000) / 1000).toFixed(4);
    //                 return (
    //                   <tr
    //                     tabIndex={0}
    //                     key={`subuser-${index}`}
    //                     onClick={e => this.toggleAccountStats(e, true, user)}
    //                     onKeyUp={e => this.toggleAccountStats(e, true, user)}
    //                   > 
    //                     <td className="user-table-status">
    //                       { this.getStatus(user) }
    //                     </td>
    //                     <td>{user.given_name} {user.family_name}</td>
    //                     <td>{user.email}</td>
    //                     <td>{user.created}</td>
    //                     {/* <td>N/A Yet</td>
    //                     <td>N/A Yet</td> */}
    //                     <td>{user.customer_accounts_created}</td>
    //                     <td>{user.files_added}</td>
    //                     <td>{totalGb}GB</td>
    //                   </tr>
    //                 );
    //               }
    //               return <div />;
    //             })
    //           : <tr><td colSpan={3}>There are no users under this account.</td></tr>
    //         }
    //         <tr>
    //           <td colSpan={4}><strong>Totals</strong></td>
    //           {/* <td><strong>Total Hours</strong></td> */}
    //           <td><strong>{totalOwners}</strong></td>
    //           <td><strong>{totalFilesAdded}</strong></td>
    //           <td><strong>{(((totalSizeAdded || 0) / 1000000) / 1000).toFixed(4)}GB</strong></td>
    //         </tr>
    //       </tbody>
    //     </Table>
    //   </div>
    // );
  }

  getStatus = (user: any) => {
    if (user.is_deleted) {
      return <img src={activeDeleted} title="Deleted"/>;
    } else if (user.subscription === 'suspended') {
      return <img src={suspendedStatus} title="Suspended" />;
    } else {
      return <img src={activeStatus} title="Active" />;
    }
  }

  searchSubmit = (currentSearch: string) => {
    this.setState({
      currentSearch
    });
  }

  toggleAccountStats = (e: any, isEditing: boolean, selectedUser = {}) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (e.keyCode) {
      if (e.keyCode !== 32 && e.keyCode !== 13) {
        return;
      }
    }
    this.setState({
      isEditing,
      showAccountStats: !this.state.showAccountStats,
      selectedUser
    });
  }

  deleteUser = (email: string) => {
    this.props.userDelete(email);
    this.toggleAccountStats({}, false);
  }

  saveUser = (user: any, isConvertingUser = false) => {
    const { creds } = this.props.user;
    const accountType = creds.attributes && creds.attributes['custom:account_type'];
    if (accountType === 'customer') {
      this.setState({
        selectedUser: {
          ...this.state.selectedUser,
          ...user
        }
      });
    }
    if (this.state.isEditing) {
      this.props.userUpdate(user);
    } else {
      this.props.userCreate(user);
    }
    if (accountType !== 'customer') {
      this.toggleAccountStats({}, false);
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Account);

import { ActionType } from 'typesafe-actions';
import * as actions from './files.actions';

export enum FilesActionTypes {
  CHECK_FILE = '[FILES] CHECK FILE',
  SET_ALL_CHECKED = '[FILES] SET ALL CHECKED',
  SET_ALL_UNCHECKED = '[FILES] SET ALL UNCHECKED',
  FILES_GET = '[FILES] GET FILES',
  FILES_GET_SUCCESS = '[FILES] GET FILES SUCCESS',
  FILES_GET_FAILURE = '[FILES] GET FILES FAILED',
  FILES_UPLOAD = '[FILES] UPLOAD',
  FILES_UPLOAD_SUCCESS = '[FILES] UPLOAD SUCCESS',
  FILES_UPLOAD_FAILURE = '[FILES] UPLOAD FAILURE',
  FILES_SORT = '[FILES] SORT'
}

export type FilesActions = ActionType<typeof actions>;

export interface FilesState {
  err: null | string;
  isLoading: boolean;
  fileList: any[];
  numChecked: number;
  numFilesChecked: number;
  numFoldersChecked: number;
  selectedFiles: any[];
  sortOptions: any;
}

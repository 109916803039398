import { AnyAction } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { action } from 'typesafe-actions';
import { FilesActionTypes } from './files.types';

export const getFiles = (): AnyAction =>
  action(FilesActionTypes.FILES_GET);

export const getFilesSuccess = (files: any): AnyAction =>
  action(FilesActionTypes.FILES_GET_SUCCESS, files);

export const getFilesFailure = (error: Error): AnyAction =>
  action(FilesActionTypes.FILES_GET_FAILURE, error);


export const uploadFiles = (): AnyAction =>
  action(FilesActionTypes.FILES_UPLOAD);

export const uploadFilesSuccess = (files: any): AnyAction =>
  action(FilesActionTypes.FILES_UPLOAD_SUCCESS, files);

export const uploadFilesFailure = (error: Error): AnyAction =>
  action(FilesActionTypes.FILES_UPLOAD_FAILURE, error);

export const checkFile = (id: string): AnyAction =>
  action(FilesActionTypes.CHECK_FILE, id);

export const setAllChecked = (parentDirectory: string): AnyAction =>
  action(FilesActionTypes.SET_ALL_CHECKED, parentDirectory);

export const setAllUnchecked = (): AnyAction =>
  action(FilesActionTypes.SET_ALL_UNCHECKED);

export const sortFiles = (options: any): AnyAction =>
  action(FilesActionTypes.FILES_SORT, options);

import * as React from 'react';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import './AccountHeader.component.scss';
import logo from '../../shared/images/logo-inverse.svg';
import { Link } from 'react-router-dom';

export default ({ user, logout,  }: any) => {
  let given_name;
  let family_name;
  let accountType;
  if (user && user.attributes) {
    accountType = user.attributes['custom:account_type'];
    given_name = user.attributes.given_name;
    family_name = user.attributes.family_name;
  }
  return (
    <Container fluid className="site-header">
      <Navbar className="d-flex align-items-center justify-content-between" expand="md">
        <Navbar.Brand>
          <img className="site-header__logo" src={logo} alt="Heirloom Cloud Logo" />
        </Navbar.Brand>
        { accountType !== 'guest' &&
          <React.Fragment>
            <Navbar.Toggle aria-controls="main-navbar" />
            <Navbar.Collapse id="main-navbar">
              {
                !!given_name && !!family_name &&
                  <Navbar.Text>
                    Welcome, {given_name} {family_name}!
                  </Navbar.Text>
              }
              <Nav className="ml-auto">
                { accountType !== 'admin' && accountType !== 'staff'
                  ? <Nav.Link as={Link} to="/home/file-manager">My Files</Nav.Link>
                  : ''
                }
                <Nav.Link as={Link} to="/home/account">My Account</Nav.Link>
                <Nav.Link onClick={() => logout()}>Sign Out</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </React.Fragment>
        }
      </Navbar>
    </Container>
  );
}

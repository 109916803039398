import React, { Component } from 'react';
import { Button, Col, Form, FormControl, ProgressBar, Row } from 'react-bootstrap';
import { XCircle, DashCircle, Plus, CheckCircle } from 'react-bootstrap-icons';
import AppLoadingScreenComponent from '../../../../shared/components/appLoadingScreen/AppLoadingScreen.component';
import { errorMessages } from '../../../../shared/constants/messages';
import { emailRegex } from '../../../../shared/constants/regex';
import { formatBytes } from '../../../../shared/util/formatBytes';
import './AccountDetails.component.scss';

class AccountDetails extends Component<any> {
  state: any;

  constructor(props: any) {
    super(props);
    this.state = {
      account_type: '',
      first_name: '',
      last_name: '',
      subscription: '',
      email: '',
      errors: {}
    };
  }

  componentDidMount() {
    const selectedUser = this.props.selectedUser;
    this.setState({
      email: selectedUser.email || '',
      given_name: selectedUser.given_name || '',
      family_name: selectedUser.family_name || '',
      passwordHidden: true,
      subscription: selectedUser.subscription || '',
      account_type: selectedUser.account_type || '',
      user_id: selectedUser.user_id || '',
      password: selectedUser.password || '',
      phone_number: selectedUser.phone_number || '',
      street_address: selectedUser.street_address || '',
      state: selectedUser.state || '',
      zip: selectedUser.zip || ''
    });
  }

  render() {
    const { accountType, isEditing, isLoading, toggleAccountStats, toggleFileUpload, selectedUser } = this.props;
    if (isLoading) {
      return <AppLoadingScreenComponent />;
    }
    return (
      <React.Fragment>
        <div className="account-details-header">
          <Button type="submit" variant="info" onClick={(e) => this.submitUser(e)}>
            <CheckCircle /> { isEditing || accountType == 'customer' ? 'Save Changes' : 'Add User' }
          </Button>
          { accountType !== 'customer' &&
            <Button type="button" variant="info" onClick={e => toggleAccountStats(e, false)}>
              <DashCircle /> Cancel { isEditing && 'Changes' }
            </Button>
          }
          { isEditing && accountType !== 'customer' &&
            <Button type="button" variant="info" onClick={this.deleteUser}>
              <XCircle /> Delete User
            </Button>
          }
          { (isEditing && ['admin', 'staff'].includes(accountType) && selectedUser.account_type === 'customer') &&
            <Button type="button" variant="info" onClick={toggleFileUpload} className="admin-button">
              <Plus /> Add Files
            </Button>
          }
          { (isEditing && ['admin', 'staff'].includes(accountType) && selectedUser.account_type === 'customer') && selectedUser.family_patriarch_id !== selectedUser.user_id &&
            <Button type="button" variant="info" onClick={(e) => this.submitUser(e, true)} className="admin-button">
              <Plus /> Make Owner
            </Button>
          }
        </div>
        <div className="account-details">
          <h2 className="account-details__heading">{ isEditing ? 'Edit User' : 'Add User' }</h2>
          <Row>
            <Col md="7">
              <Form>
                { (accountType === 'admin' || accountType === 'staff') &&
                  <Form.Row>
                    <Col sm="6">
                      <Form.Group controlId="account_type">
                        <Form.Label>Account Type</Form.Label>
                        <Form.Control as="select" size="lg" required
                          defaultValue={selectedUser.account_type} readOnly={isEditing}
                          isInvalid={!!this.state.errors.account_type}
                          onChange={e => this.updateField('account_type', e.target.value)}>
                            <option>Select...</option>
                            { accountType === 'admin' ? <option value="admin">Admin</option> : '' }
                            { accountType === 'admin' ? <option value="staff">Staff</option> : '' }
                            <option value="customer">Customer</option>
                        </Form.Control>
                        <FormControl.Feedback type="invalid">{ this.state.errors.account_type }</FormControl.Feedback>
                      </Form.Group>
                    </Col>
                    <Col sm="6">
                        <Form.Group controlId="subscription">
                          <Form.Label>Subscription</Form.Label>
                          <Form.Control as="select" size="lg" required readOnly={!(isEditing && this.state.account_type === 'customer' || !isEditing)}
                            defaultValue={selectedUser.subscription}
                            isInvalid={!!this.state.errors.subscription}
                            onChange={e => this.updateField('subscription', e.target.value)}>
                              <option>Select...</option>
                              <option value="unlimited">Unlimited</option>
                              <option value="500gb">500GB</option>
                              <option value="2000gb">2000GB</option>
                              <option value="suspended">Suspended</option>
                          </Form.Control>
                          <FormControl.Feedback type="invalid">{ this.state.errors.subscription }</FormControl.Feedback>
                        </Form.Group>
                      </Col>
                  </Form.Row>
                }
                <Form.Row>
                  <Col>
                    <Form.Group controlId="email">
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control value={this.state.email} readOnly={isEditing}
                          isInvalid={!!this.state.errors.email}
                          type="email" size="lg" required autoComplete="off"
                          onChange={e => this.updateField('email', e.target.value)}
                        />
                        <FormControl.Feedback type="invalid">{ this.state.errors.email }</FormControl.Feedback>
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col sm="6">
                    <Form.Group controlId="given_name">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control value={this.state.given_name}
                        isInvalid={!!this.state.errors.given_name}
                        type="text" size="lg" required autoComplete="off"
                        onChange={e => this.updateField('given_name', e.target.value)}
                      />
                      <FormControl.Feedback type="invalid">{ this.state.errors.given_name }</FormControl.Feedback>
                    </Form.Group>
                  </Col>
                  <Col sm="6">
                    <Form.Group controlId="family_name">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control value={this.state.family_name}
                        isInvalid={!!this.state.errors.family_name}
                        type="text" size="lg" required autoComplete="off"
                        onChange={e => this.updateField('family_name', e.target.value)}
                      />
                      <FormControl.Feedback type="invalid">{ this.state.errors.family_name }</FormControl.Feedback>
                    </Form.Group>
                  </Col>
                </Form.Row>
                {/* <Form.Row>
                  <Col sm="6">
                    <label htmlFor="password">Password</label>
                    <InputGroup>
                      <Form.Control id="password" placeholder="Password" value={this.state.password}
                        type={this.state.passwordHidden ? 'password': 'text'} size="lg" required autoComplete="off"
                        isInvalid={!this.state.password}
                        onChange={(e) => this.updateField('password', e.target.value)}/>
                      <InputGroup.Append 
                        className="account-details__password-show"
                        onClick={() => this.setState({ passwordHidden: !this.state.passwordHidden })}>
                        <InputGroup.Text>
                          {
                            this.state.passwordHidden
                              ? <EyeSlashFill />
                              : <EyeFill />
                          }
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  </Col>
                </Form.Row> */}
                {/* <Form.Row>
                  <Col sm="6">
                    <Form.Group controlId="phone_number">
                      <Form.Label>Phone Number (x-xxx-xxx-xxxx)</Form.Label>
                      <Form.Control value={this.state.phone_number}
                        isInvalid={!!this.state.errors.phone} placeholder="x-xxx-xxx-xxxx"
                        type="tel" size="lg" required autoComplete="off"
                        onChange={e => this.updateField('phone_number', e.target.value)}
                      />
                      <FormControl.Feedback type="invalid">{ this.state.errors.phone }</FormControl.Feedback>
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Group controlId="street-address">
                    <Form.Label>Street Address</Form.Label>
                    <Form.Control value={this.state.street_address}
                      isInvalid={!this.state.street_address}
                      type="text" size="lg" required autoComplete="off"
                      onChange={e => this.updateField('street_address', e.target.value)}
                    />
                    <FormControl.Feedback type="invalid">Street Address is required.</FormControl.Feedback>
                </Form.Group>
                <Form.Row>
                  <Col sm="6">
                    <Form.Group controlId="state">
                      <Form.Label>State</Form.Label>
                      <Form.Control as="select" required size="lg"
                        isInvalid={!this.state.state}
                        value={this.state.state}
                        onChange={e => this.updateField('state', e.target.value)}
                      >
                        { states.map(state => <option key={state} value={state}>{ state }</option>) }
                      </Form.Control>
                      <FormControl.Feedback type="invalid">State is required.</FormControl.Feedback>
                    </Form.Group>
                  </Col>
                  <Col sm="6">
                    <Form.Group controlId="zip">
                      <Form.Label>Zip</Form.Label>
                      <Form.Control value={this.state.zip}
                        isInvalid={!this.state.zip}
                        type="text" size="lg" required autoComplete="off"
                        onChange={e => this.updateField('zip', e.target.value)}
                      />
                      <FormControl.Feedback type="invalid">{ this.state.errors.zip }</FormControl.Feedback>
                    </Form.Group>
                  </Col>
                </Form.Row> */}
              </Form>
            </Col>
            <Col>
              <div className="account-details__statistics">
                { this.getAccountStatistics() }
              </div>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }

  deleteUser = () => {
    if (confirm('Are you sure you want to delete this user?')) {
      this.props.deleteUser(this.props.selectedUser.email);
    }
  }

  getHeading = () => {
    
  }

  getAccountStatistics = () => {
    const { accountType, selectedUser } = this.props;
    const { admin_accounts_created, subscription, family_storage_used, size_added, files_added, files_deleted, staff_accounts_created, customer_accounts_created } = selectedUser;
    let spaceUsed = 0;
    let totalGb = formatBytes(family_storage_used || size_added);
    let totalFiles = 0;
    if (typeof files_added === 'number' && typeof files_deleted === 'number') {
      totalFiles = files_added - files_deleted;
    } else {
      totalFiles = files_added || 0;
    }
    if (accountType === 'admin' || accountType === 'staff') {
      return (
        <dl>
          <dt>Total Files</dt>
          <dd>{ totalFiles || 0 }</dd>
          <dt>Storage Used</dt>
          <dd>{totalGb}</dd>
          <dt>Admin Accounts Created</dt>
          <dd>{ admin_accounts_created || 0 }</dd>
          <dt>Staff Accounts Created</dt>
          <dd>{ staff_accounts_created || 0 }</dd>
          <dt>Customer Accounts Created</dt>
          <dd>{ customer_accounts_created || 0 }</dd>
        </dl>
      );
    } else {
      switch (subscription) {
        case '2000gb':
          spaceUsed = Math.round((family_storage_used || size_added) / 2147483648000);
          break;
        case '500gb':
          spaceUsed = Math.round((family_storage_used || size_added) / 536870912000);
          break;
        default:
          break;
      }
      return (
        <dl>
          <dt>Total Files</dt>
          <dd>{ totalFiles || 0 }</dd>
          <dt>Storage Used</dt>
          <dd>{totalGb}</dd>
          { subscription !== 'unlimited'
            && <ProgressBar animated striped variant="success" now={spaceUsed} label={`${spaceUsed}%`} /> }
        </dl>
      );
    }
  }

  submitUser = (e: any, isConverting = false) => {
    e.preventDefault();
    const { accountType, isEditing, selectedUser } = this.props;
    const {
      account_type,
      email,
      given_name,
      family_name,
      subscription
    } = this.state;
    let emailError = '';
    let firstNameError = '';
    let lastNameError = '';
    let subscriptionError = '';
    let accountTypeError = '';
    if (!given_name) {
      firstNameError = errorMessages.firstNameRequired;
    }
    if (!family_name) {
      lastNameError = errorMessages.lastNameRequired;
    }
    if (!subscription && accountType !== 'customer') {
      subscriptionError = errorMessages.subscriptionRequired;
    }
    if (!account_type) {
      accountTypeError = errorMessages.accountTypeRequired;
    }
    if (email) {
      if (!emailRegex.test(email)) {
        emailError = errorMessages.emailInvalid;
      }
    } else {
      emailError = errorMessages.emailRequired;
    }
    this.setState({
      errors: {
        account_type: accountTypeError,
        email: emailError,
        first_name: firstNameError,
        last_name: lastNameError,
        subscription: subscriptionError,
      }
    });
    if (!emailError && !firstNameError && !lastNameError && !subscriptionError) {
      let userObj: any = {
        email,
        given_name,
        family_name
      };
      if (accountType !== 'customer' && isEditing) {
        userObj = {
          email,
          subscription
        };
      }
      if (!isEditing) {
        userObj.account_type = accountType !== 'customer' ? account_type : 'customer';
      }
      if ((!isEditing || subscription !== selectedUser.subscription) && accountType !== 'customer') {
        userObj.subscription = subscription;
      }
      if (isConverting) {
        userObj = {
          email,
          family_patriarch_id: selectedUser.user_id,
          subscription
        };
      }
      this.props.saveUser(userObj);
    }
  }

  updateField = (key: string, value: string): void => {
    this.setState({
      [key]: value
    });
  }
};

export default AccountDetails;

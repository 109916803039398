import { Reducer } from 'redux';
import { MessagingActionTypes } from '../messaging/messaging.types';
import { UserActions, UserActionTypes, UserState } from './user.types';

const defaultUserState: UserState = {
  error: null,
  addEditUserError: null,
  userListError: null,
  isLoading: false,
  isUserListLoading: false,
  isLoggedIn: false,
  successMessage: null,
  creds: {},
  userList: []
};

export const User: Reducer<UserState, UserActions> = (state = defaultUserState, action) => {
  switch (action.type) {
    case UserActionTypes.USER_LOGIN:
    case UserActionTypes.USER_HAS_SESSION:
    case UserActionTypes.SUBMIT_PASSWORD_CHANGE:
    case UserActionTypes.SUBMIT_FORGOT_PASSWORD:
      return {
        ...state,
        error: null,
        isLoading: true,
        isLoggedIn: false
      };
    case UserActionTypes.USER_LOGIN_SUCCESS:
      return {
        ...state,
        error: null,
        isLoading: false,
        isLoggedIn: true,
        creds: action.payload
      };
    case UserActionTypes.USER_LOGIN_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        isLoading: false,
        isLoggedIn: false,
        creds: action.payload.user
      };

    case UserActionTypes.USER_SIGNUP:
      return {
        ...state,
        error: null,
        isLoading: true,
        isLoggedIn: false
      };
    case UserActionTypes.USER_SIGNUP_SUCCESS:
      return {
        ...state,
        error: null,
        isLoading: false,
        isLoggedIn: false
      };
    case UserActionTypes.USER_SIGNUP_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        isLoggedIn: false
      };

    case UserActionTypes.USER_CREATE:
      return {
        ...state,
        addEditUserError: null,
        isLoading: true,
      };
    case UserActionTypes.USER_CREATE_SUCCESS:
      return {
        ...state,
        addEditUserError: null,
        isLoading: false,
        userList: state.userList.concat(action.payload)
      }
    case UserActionTypes.USER_CREATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        addEditUserError: action.payload
      }

      case UserActionTypes.USER_DELETE:
        return {
          ...state,
          addEditUserError: null,
          isLoading: true,
        };
      case UserActionTypes.USER_DELETE_SUCCESS:
        const { userList } = state;
        const updatedUserList = userList.filter(user => user.email !== action.payload);
        return {
          ...state,
          addEditUserError: null,
          isLoading: false,
          userList: updatedUserList
        }
      case UserActionTypes.USER_DELETE_FAILURE:
        return {
          ...state,
          isLoading: false,
          addEditUserError: action.payload
        }

    case UserActionTypes.USER_ACCOUNT_RECOVER:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case UserActionTypes.USER_ACCOUNT_RECOVER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        successMessage: action.payload,
        error: null
      };
    case UserActionTypes.USER_ACCOUNT_RECOVER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      }

    case UserActionTypes.USER_GET_USER_LIST:
      return {
        ...state,
        isUserListLoading: true,
        userListError: null
      };
      case UserActionTypes.USER_GET_USER_LIST_SUCCESS:
        return {
          ...state,
          isUserListLoading: false,
          userListError: null,
          userList: action.payload
        };
      case UserActionTypes.USER_GET_USER_LIST_FAILURE:
        return {
          ...state,
          isUserListLoading: false,
          userListError: action.payload
        };
      case UserActionTypes.USER_LOGOUT:
        return defaultUserState;

      case UserActionTypes.USER_UPDATE:
        return {
          ...state,
          isLoading: true
        };
      case UserActionTypes.USER_UPDATE_SUCCESS:
        return {
          ...state,
          isLoading: false
        };
      case UserActionTypes.USER_UPDATE_FAILURE:
        return {
          ...state,
          isLoading: false,
          err: action.payload
        };
      case UserActionTypes.SUBMIT_PASSWORD_CHANGE_SUCCESS:
        return {
          ...state,
          isLoading: false
        }
      case UserActionTypes.SUBMIT_PASSWORD_CHANGE_FAILURE:
        return {
          ...state,
          isLoading: false
        };

      case UserActionTypes.USER_CLEAR_USER_LIST:
        return {
          ...state,
          userList: []
        };

      case MessagingActionTypes.MESSAGING_REMOVE_ERRORS:
        return {
          ...state,
          error: null,
          addEditUserError: null,
          userListError: null,
          successMessage: null
        };
      default:
        return state;
  }
};

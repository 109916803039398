import { AnyAction } from 'redux';
import { action } from 'typesafe-actions';
import { LoginCreds, UserActionTypes } from './user.types';

export const userLogin = (loginCreds: LoginCreds): AnyAction =>
  action(UserActionTypes.USER_LOGIN, loginCreds);

export const userLoginSuccess = (creds: any): AnyAction =>
  action(UserActionTypes.USER_LOGIN_SUCCESS, creds);

export const userLoginFailure = (error: string, user?: any): AnyAction =>
  action(UserActionTypes.USER_LOGIN_FAILURE, { error, user });

export const userSignup = (loginCreds: LoginCreds): AnyAction =>
  action(UserActionTypes.USER_SIGNUP, loginCreds);

export const userSignupSuccess = (creds: any): AnyAction =>
  action(UserActionTypes.USER_SIGNUP_SUCCESS, creds);

export const userSignupFailure = (error: string): AnyAction =>
  action(UserActionTypes.USER_SIGNUP_FAILURE, error);

export const userCreate = (loginCreds: LoginCreds): AnyAction =>
  action(UserActionTypes.USER_CREATE, loginCreds);

export const userCreateSuccess = (creds: any): AnyAction =>
  action(UserActionTypes.USER_CREATE_SUCCESS, creds);

export const userCreateFailure = (error: string): AnyAction =>
  action(UserActionTypes.USER_CREATE_FAILURE, error);

export const userDelete = (email: string): AnyAction =>
  action(UserActionTypes.USER_DELETE, email);

export const userDeleteSuccess = (email: string): AnyAction =>
  action(UserActionTypes.USER_DELETE_SUCCESS, email);

export const userDeleteFailure = (error: string): AnyAction =>
  action(UserActionTypes.USER_DELETE_FAILURE, error);

export const userHasSession = (): AnyAction =>
  action(UserActionTypes.USER_HAS_SESSION);

export const userLogout = (): AnyAction =>
  action(UserActionTypes.USER_LOGOUT);

export const userRecovery = (email: string): AnyAction =>
  action(UserActionTypes.USER_ACCOUNT_RECOVER, email);

export const userRecoverySuccess = (message: string): AnyAction =>
  action(UserActionTypes.USER_ACCOUNT_RECOVER_SUCCESS, message);
  
export const userRecoveryFailure = (error: string): AnyAction =>
  action(UserActionTypes.USER_ACCOUNT_RECOVER_FAILURE, error);

export const userGetUserList = (user: string = '*'): AnyAction =>
  action(UserActionTypes.USER_GET_USER_LIST, user);

export const userGetUserListSuccess = (users: any): AnyAction =>
  action(UserActionTypes.USER_GET_USER_LIST_SUCCESS, users);
  
export const userGetUserListFailure = (error: string): AnyAction =>
  action(UserActionTypes.USER_GET_USER_LIST_FAILURE, error);

export const userUpdate = (user: any): AnyAction =>
  action(UserActionTypes.USER_UPDATE, user);

export const userUpdateSuccess = (): AnyAction =>
  action(UserActionTypes.USER_UPDATE_SUCCESS);
  
export const userUpdateFailure = (error: string): AnyAction =>
  action(UserActionTypes.USER_UPDATE_FAILURE, error);

export const submitPasswordChange = (password: string): AnyAction =>
  action(UserActionTypes.SUBMIT_PASSWORD_CHANGE, password);

export const submitForgotPassword = (password: string, verificationCode: string, email: string): AnyAction =>
  action(UserActionTypes.SUBMIT_FORGOT_PASSWORD, { password, verificationCode, email });

export const submitPasswordChangeSuccess = (): AnyAction =>
  action(UserActionTypes.SUBMIT_PASSWORD_CHANGE_SUCCESS);

export const submitPasswordChangeFailure = (error: string): AnyAction =>
  action(UserActionTypes.SUBMIT_PASSWORD_CHANGE_FAILURE, error);

export const clearUserList = (): AnyAction =>
  action(UserActionTypes.USER_CLEAR_USER_LIST);



import React, { Component } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import videojs from 'video.js'
import 'video.js/dist/video-js.css';
import { Document, Page, pdfjs } from "react-pdf";
import { Button } from 'react-bootstrap';
import { ArrowLeft, ArrowRight } from 'react-bootstrap-icons';
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";

import './FileViewer.component.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default class FileViewer extends Component<any> {
  player: any;
  videoNode: any = React.createRef();
  state: any;

  constructor(props: any) {
    super(props);
    this.state = {
      pageNumber: 1,
      numPages: null
    }
  }

  componentDidMount() {
    // instantiate Video.js
    const { file } = this.props;
    if (file.content_type.includes('video')) {
      const options = {
        autoplay: false,
        controls: true,
        sources: [{
          src: file.url,
          type: file.content_type
        }]
      };
      this.player = videojs(this.videoNode, options);
    }
  }

  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose()
    }
  }

  render() {
    const Driver = this.getDriver();
    const { file } = this.props;
    return (
      <div className="heirloom-file-wrapper">
        <h1>{file.displayName}</h1>
        { Driver }
      </div>
    );
  }

  getDriver = (): JSX.Element => {
    const { file } = this.props;
    if (file) {
      let fileType = file.content_type.split('/')[1];
      if (file.content_type.includes('video')) {
        fileType = 'video';
      } else if (file.content_type.includes('application')) {
        fileType = 'application';
      }
      let Driver = <p>File Type Not Supported.</p>;
      switch (fileType) {
        case 'jpeg':
        case 'jpg':
        case 'png':
        case 'jfif':
        case 'gif':
          Driver = (
            <React.Fragment>
              { file.description && <p className="file-description">{file.description}</p> }
              <img src={file.url} alt={file.name} title={file.name} />
            </React.Fragment>
          );
          break;
        case 'video':
          Driver = (
            <React.Fragment>
              { file.description && <p className="file-description">{file.description}</p> }
              <div data-vjs-player>
                <video
                  ref={node => this.videoNode = node}
                  id="heirloom-video-player"
                  className="video-js"
                />
              </div>
            </React.Fragment>
          );
          break;
        case 'mp3':
        case 'mpeg':
          Driver = (
            <React.Fragment>
              { file.description && <p className="file-description">{file.description}</p> }
              <ReactAudioPlayer
                src={file.url}
                controls
              />
            </React.Fragment>
          );
          break;
        case 'pdf':
          Driver = (
            <div className="pdf-viewer">
              <div className="pdf-viewer__body">
                { file.description && <p className="file-description">{file.description}</p> }
                <Document
                  file={file.url}
                  onLoadSuccess={this.onDocumentLoadSuccess}
                  onLoadError={console.log}
                >
                  <Page pageNumber={this.state.pageNumber} />
                </Document>
              </div>
              <div className="pdf-viewer__footer">
                <p>Page {this.state.pageNumber} of {this.state.numPages}</p>
                <Button onClick={this.backPage}><ArrowLeft /></Button>
                <Button onClick={this.forwardPage}><ArrowRight /></Button>
              </div>
            </div>
          );
          break;
        case 'application':
        case 'docx':
        case 'doc':
        case 'ppt':
        case 'pptx':
        case 'tiff':
        case 'xls':
        case 'xlsx':
        case 'html':
        case 'plain':
          Driver = (
            <React.Fragment>
              { file.description && <p className="file-description">{file.description}</p> }
              <DocViewer
                documents={[{
                  uri: file.url,
                  fileType: file.content_type
                }]}
                pluginRenderers={DocViewerRenderers}
                className="doc-viewer" />
            </React.Fragment>
          );
          break;
      }
      return Driver;
    }
    return <div />;
  }

  onDocumentLoadSuccess = ({ numPages }: any) => {
    this.setState({
      numPages
    });
  }

  backPage = () => {
    this.setState({
      pageNumber: this.state.pageNumber === 1 ? this.state.numPages : this.state.pageNumber - 1
    });
  }

  forwardPage = () => {
    this.setState({
      pageNumber: this.state.pageNumber === this.state.numPages ? 1 : this.state.pageNumber + 1
    });
  }
}

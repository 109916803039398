import * as React from 'react';
import { InputGroup, Form } from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';

import './PageHeader.component.scss';

export default ({ searchSubmit, showSearch, title, searchPlaceholder, navigateFolders }: any) => {
  let searchValue = '';
  let heading: any;
  if (Array.isArray(title)) {
    heading = (
      <nav className="page-header__directory-nav">
        {
          title.map((file: any, index) => 
            <a
              className={ index === title.length - 1 ? "page-header__directory-link page-header__directory-link--no-events" : "page-header__directory-link"}
              onClick={(e) => navigateFolders(e, index)}
              title={file.name}
              key={`breadcrumb-link-${index}`}
            >
                {file.name.length > 20 && index !== title.length - 1 ? `${file.name.substring(0, 20)}... ${index !== title.length - 1 ? '/ ' : ''}` : `${file.name} ${index !== title.length - 1 ? '/ ' : ''}`}
            </a>
          )
        }
      </nav>
    )
  } else {
    heading = <h1 className="page-header__title">{ title }</h1>;
  }
  return (
    <div className="page-header d-md-flex justify-content-between align-items-center">
      { heading }
      { showSearch ?
        <Form onSubmit={() => searchSubmit(searchValue)}>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text><Search /></InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control id="search" type="search" placeholder={ searchPlaceholder || "Search my memories..." }
              onChange={(e) => {
                searchValue = e.target.value;
                searchSubmit(e.target.value);
              }}/>
          </InputGroup>
        </Form>
        : ''
      }
    </div>
  );
}

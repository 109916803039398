export const lvl3Permissions = {
  moveFiles: {
    disabled: false,
    message: 'You have this permission as an owner/editor.'
  },
  uploadFiles: {
    disabled: false,
    message: 'You have this permission as an owner/editor.'
  },
  newFolder: {
    disabled: false,
    message: 'You have this permission as an owner/editor.'
  },
  share: {
    disabled: false,
    message: 'You have this permission as an owner/editor.'
  },
  rename: {
    disabled: false,
    message: 'You have this permission as an owner/editor.'
  },
  copy: {
    disabled: false,
    message: 'You have this permission as an owner/editor.'
  },
  delete: {
    disabled: false,
    message: 'You have this permission as an owner/editor.'
  },
  socialMediaShare: {
    disabled: false,
    message: 'You have this permission as a Contributor.'
  }
};

export const lvl2Permissions = {
  moveFiles: {
    disabled: true,
    message: 'One or more of the selected files do not permit moving files as a Contributor.'
  },
  uploadFiles: {
    disabled: false,
    message: 'You have this permission as a Contributor.'
  },
  newFolder: {
    disabled: false,
    message: 'You have this permission as a Contributor.'
  },
  share: {
    disabled: true,
    message: 'You don\'t have share permission as a Contributor.'
  },
  rename: {
    disabled: false,
    message: 'You have this permission as a Contributor.'
  },
  copy: {
    disabled: false,
    message: 'You have this permission as a Contributor.'
  },
  delete: {
    disabled: true,
    message: 'One or more of the selected files do not permit deleting files as a Contributor.'
  },
  socialMediaShare: {
    disabled: false,
    message: 'You have this permission as a Contributor.'
  }
};

export const lvl1Permissions = {
  moveFiles: {
    disabled: true,
    message: 'One or more of the selected files do not permit moving files as a Viewer.'
  },
  uploadFiles: {
    disabled: true,
    message: 'The current folder does do not permit uploading files as a Viewer.'
  },
  newFolder: {
    disabled: true,
    message: 'The current folder does not permit creating new folders as a Viewer.'
  },
  share: {
    disabled: true,
    message: 'The selected file cannot be shared as a Viewer.'
  },
  rename: {
    disabled: true,
    message: 'The selected file cannot be renamed as a Viewer'
  },
  copy: {
    disabled: true,
    message: 'One or more of the selected files do not permit copying files as a Viewer.'
  },
  delete: {
    disabled: true,
    message: 'One or more of the selected files do not permit deleting files as a Viewer.'
  },
  socialMediaShare: {
    disabled: false,
    message: 'You have this permission as a Viewer.'
  }
};

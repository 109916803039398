import React, { useState } from 'react';
import { Button, Form, FormControl, InputGroup, Spinner } from 'react-bootstrap';
import { EyeFill, EyeSlashFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { passwordRegex } from '../../../shared/constants/regex';

export default ({ submitPasswordChange, user, recoveryEmail }: any) => {
  const [password, setPassword] = useState('');
  const [passwordVerify, setPasswordVerify] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  let error: any = <span />;

  if (password && password !== passwordVerify) {
    error = (
      <span className="invalid-feedback d-block">
        Passwords do not match
      </span>
    )
  } else if (password && !passwordRegex.test(password)) {
    error = (
      <span className="invalid-feedback d-block">
        Invalid Password, follow above guidlines
      </span>
    );
  }

  return (
    <React.Fragment>
      <h1>Change Password</h1>
      <Link to="/login" className="login-component__back-button">
        &laquo; Back to Login
      </Link>
      { !user.creds && !!recoveryEmail && 
        <p>A verification code was sent to your email. Please create your new password to access your account.</p>
      }
      <p>Passwords must be eight characters, with at least one of each type: Upper case, Lower case, number, special characater (!@#$%).</p>
      <p>If you are having trouble accessing your account please call 1-800-284-9939.</p>
      <Form onSubmit={(e) => submitPasswordChange(e, password, verificationCode, recoveryEmail)}>
        { !user.creds && !!recoveryEmail &&
            <React.Fragment>
              <Form.Group controlId="verification-code">
                <Form.Label>Verification Code</Form.Label>
                <Form.Control
                  autoComplete="off"
                  type="text"
                  size="lg"
                  required
                  isInvalid={!verificationCode}
                  onChange={e => setVerificationCode(e.target.value)}
                  disabled={user.isLoading}
                  value={verificationCode}
                />
                <FormControl.Feedback type="invalid">
                  Required
                </FormControl.Feedback>
              </Form.Group>
            </React.Fragment>
        }
        <label htmlFor="password" className="login-component__password-label">Password</label>
        <InputGroup>
          <Form.Control
            autoComplete="off"
            type={showPassword ? 'text' : 'password'}
            size="lg"
            required
            isInvalid={!password || password !== passwordVerify}
            onChange={e => setPassword(e.target.value)}
            disabled={user.isLoading}
            value={password}
          />
          <InputGroup.Append 
            className="account-details__password-show"
            onClick={() => setShowPassword(!showPassword)}>
            <InputGroup.Text>
              {
                !showPassword
                  ? <EyeSlashFill />
                  : <EyeFill />
              }
            </InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
        { error }
        <label htmlFor="password-veryify" className="login-component__password-label">Confirm Password</label>
        <InputGroup>
          <Form.Control
            autoComplete="off"
            type={showConfirmPassword ? 'text' : 'password'}
            size="lg"
            required
            isInvalid={!password || password !== passwordVerify}
            onChange={e => setPasswordVerify(e.target.value)}
            disabled={user.isLoading}
            value={passwordVerify}
          />
          <InputGroup.Append 
            className="account-details__password-show"
            onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
            <InputGroup.Text>
              {
                !showConfirmPassword
                  ? <EyeSlashFill />
                  : <EyeFill />
              }
            </InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
        { error }
        <Button
          type="submit"
          variant="primary"
          size="lg"
          block
          disabled={!password || !passwordVerify || password !== passwordVerify}
          className="has-spinner"
        >
          Change Password
          {user.isLoading ? (
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
          ) : (
            ''
          )}
        </Button>
      </Form>
    </React.Fragment>
  );
};

import { AnyAction } from 'redux';
import { action } from 'typesafe-actions';
import { ToastMessage, MessagingActionTypes } from './messaging.types';

export const toastsAdd = (toast: ToastMessage): AnyAction =>
  action(MessagingActionTypes.MESSAGING_TOAST_ADD, toast);

export const toastsRemove = (): AnyAction =>
  action(MessagingActionTypes.MESSAGING_TOAST_REMOVE);

export const removeErrors = (): AnyAction =>
  action(MessagingActionTypes.MESSAGING_REMOVE_ERRORS);

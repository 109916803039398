import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { BoxArrowInRight, CheckCircle, CursorText, Download,
  FilePlus, People, Trash, XCircle } from 'react-bootstrap-icons';
  import { FacebookShareButton, FacebookIcon, PinterestShareButton, PinterestIcon } from "react-share";

export default class SidebarComponent extends Component<any> {
  state: any;
  fbRef: any = React.createRef();
  pintRef: any = React.createRef();

  constructor(props: any) {
    super(props);
    this.state = {
      shareUrl: '',
      shareDescription: ''
    }
  }

  generatedShareUrl = async (e: any, button: string) => {
    const { share, getSharedContent } = this.props;
    e.preventDefault();
    
    const shareBucket = await share();
    if (shareBucket) {
      const shareUrlId = shareBucket.shared_link_id;
      const sharedContentBucket = await getSharedContent(shareUrlId);
      if (sharedContentBucket && sharedContentBucket.files && sharedContentBucket.files.length) {
        const { url, description } = sharedContentBucket.files[0];
        this.setState({
          shareUrl: url,
          shareDescription: `Shared from Heirloom.cloud\n ${description}`
        },
          () => {
            switch (button) {
              case 'facebook':
                this.fbRef.click();
                break;
              case 'pinterest':
                this.pintRef.click();
                break;
              default:
                break;
            }
          });
      }
    }
  }

  render() {
    const { currentFile, updateFiles, downloadFiles,
      toggleModal, files, toggleTrash, isViewingTrash, getUserFileAccess, permissions } = this.props;
    const { shareUrl, shareDescription } = this.state;
    const { numChecked, selectedFiles } = files;
    
    let shareState = 'sidebar__socials';
    if (permissions.socialMediaShare.disabled || !currentFile && numChecked !== 1
      || (selectedFiles[0] && (!selectedFiles[0].content_type.includes('image') || selectedFiles[0].content_type.includes('tiff')))
      || (currentFile && (!currentFile.content_type.includes('image') || currentFile.content_type.includes('tiff')))) {
      shareState = 'sidebar__socials disabled';
    }
    return (
      <React.Fragment>
        <div className="sidebar__wrapper">
          <div className="file-manager__sidebar-buttons">
            <div className="sidebar__header">
              <div className="sidebar__directions">
                <p>Select a file or folder to perform actions.</p>
              </div>
              <div className="sidebar__file-info"></div>
              <div className="sidebar__file-access"></div>
            </div>
            { !isViewingTrash &&
              <React.Fragment>
                <Button
                  variant="primary"
                  disabled={permissions.share.disabled || (!currentFile ? numChecked < 1 : false)}
                  onClick={() => getUserFileAccess()}
                  title={permissions.share.message}
                >
                  <People /> Share
                </Button>
                <Button
                  variant="primary"
                  disabled={!currentFile ? numChecked < 1 : false}
                  onClick={() => downloadFiles()}
                  title="You have permission to download files."
                >
                  <Download /> Download
                </Button>
                <Button
                  variant="primary"
                  onClick={() => toggleModal('showRenameFilesModal')}
                  disabled={permissions.rename.disabled || (!currentFile ? numChecked !== 1 : false)}
                  title={permissions.rename.message}
                >
                  <CursorText /> Rename
                </Button>
                <Button
                  variant="primary"
                  onClick={() => toggleModal('showMoveFilesModal')}
                  disabled={permissions.moveFiles.disabled || (!currentFile ? numChecked < 1 : false)}
                  title={permissions.moveFiles.message}
                >
                  <BoxArrowInRight /> Move
                </Button>
                <Button
                  variant="primary"
                  onClick={() => toggleModal('showCopyFilesModal')}
                  disabled={permissions.copy.disabled || (!currentFile ? numChecked < 1 : false)}
                  title={permissions.copy.message}
                >
                  <FilePlus /> Copy
                </Button>
                {/* <Button variant="primary" disabled={!currentFile ? (numFilesChecked !== 1 || numFoldersChecked > 1) : false} onClick={() => share()}>
                  <Share /> Share
                </Button> */}
                <Button
                  variant="primary"
                  onClick={() => updateFiles(false, true)}
                  disabled={permissions.delete.disabled || (!currentFile ? numChecked < 1 : false)}
                  title={permissions.delete.message}  
                >
                  <XCircle /> Delete
                </Button>
                <div className={shareState} title={permissions.socialMediaShare.message}>
                  <span onClick={(e) => this.generatedShareUrl(e, 'facebook')} className="sidebar__shareMask">
                    <FacebookShareButton
                      onClick={(e) => {
                        e.stopPropagation();
                        e.nativeEvent.stopImmediatePropagation();
                      }}
                      ref={ref => this.fbRef = ref}
                      url={shareUrl}
                      quote={shareDescription}>
                      <FacebookIcon size={36} round={true}/>
                    </FacebookShareButton>
                  </span>
                  <span onClick={(e) => this.generatedShareUrl(e, 'pinterest')} className="sidebar__shareMask">
                    <PinterestShareButton
                      onClick={(e) => {
                        e.stopPropagation();
                        e.nativeEvent.stopImmediatePropagation();
                      }}
                      ref={ref => this.pintRef = ref}
                      url={'https://frontend.heirloom-dev.triumphtech.io/'}
                      media={shareUrl}
                      description={shareDescription}>
                      <PinterestIcon size={36} round={true}/>
                    </PinterestShareButton>
                  </span>
                </div>
              </React.Fragment>
            }
            {
              isViewingTrash &&
              <Button variant="primary" onClick={() => updateFiles(true)} disabled={!currentFile ? numChecked < 1 : false}>
                <CheckCircle /> Restore
              </Button>
            }
          </div>
        </div>
        <a className="sidebar__trash-button" onClick={() => toggleTrash()}><Trash /> { isViewingTrash && 'Exit' } Trash</a>
      </React.Fragment>
    );
  }
};

import { ActionType } from 'typesafe-actions';
import * as actions from './user.actions';

export enum UserActionTypes {
  USER_CLEAR_USER_LIST = '[USER] CLEAR USER LIST',
  USER_LOGIN = '[USER] LOGIN',
  USER_LOGIN_SUCCESS = '[USER] LOGIN SUCCESS',
  USER_LOGIN_FAILURE = '[USER] LOGIN FAILED',
  USER_LOGOUT = '[USER] LOG OUT',
  USER_HAS_SESSION = '[USER] HAS SESSION',
  USER_SIGNUP = '[USER] SIGNUP',
  USER_SIGNUP_SUCCESS = '[USER] SIGNUP SUCCESS',
  USER_SIGNUP_FAILURE = '[USER] SIGNUP FAILURE',
  USER_CREATE = '[USER] CREATE',
  USER_CREATE_SUCCESS = '[USER] CREATE SUCCES',
  USER_CREATE_FAILURE = '[USER] CREATE FAILURE',
  USER_DELETE = '[USER] DELETE',
  USER_DELETE_SUCCESS = '[USER] DELETE SUCCESS',
  USER_DELETE_FAILURE = '[USER] DELETE FAILURE',
  USER_ACCOUNT_RECOVER = '[USER] ACCOUNT RECOVER',
  USER_ACCOUNT_RECOVER_SUCCESS = '[USER] ACCOUNT RECOVER SUCCESS',
  USER_ACCOUNT_RECOVER_FAILURE = '[USER] ACCOUNT RECOVER FAILURE',
  USER_GET_USER_LIST = '[USER] GET USER LIST',
  USER_GET_USER_LIST_SUCCESS = '[USER] GET USER LIST SUCCESS',
  USER_GET_USER_LIST_FAILURE = '[USER] GET USER LIST FAILURE',
  USER_UPDATE = '[USER] UPDATE',
  USER_UPDATE_SUCCESS = '[USER] UPDATE SUCCESS',
  USER_UPDATE_FAILURE = '[USER] UPDATE FAILURE',
  USER_RESET_MESSAGES = '[USER] RESET MESSAGES',
  SUBMIT_PASSWORD_CHANGE = '[USER] SUBMIT PASSWORD CHANGE',
  SUBMIT_FORGOT_PASSWORD = '[USER] SUBMIT FORGOT PASSWORD',
  SUBMIT_PASSWORD_CHANGE_SUCCESS = '[USER] SUBMIT PASSWORD CHANGE SUCCESS',
  SUBMIT_PASSWORD_CHANGE_FAILURE = '[USER] SUBMIT PASSWORD CHANGE FAILURE'
}

export type UserActions = ActionType<typeof actions>;

export interface LoginCreds {
  username: string;
  password: string;
}

export interface UserState {
  error: null | string;
  addEditUserError: null | string;
  userListError: null | string;
  isLoading: boolean;
  isUserListLoading: boolean;
  isLoggedIn: boolean;
  successMessage: null | string;
  creds: any;
  userList: any[];
}

import { ActionType } from 'typesafe-actions';
import * as actions from './messaging.actions';

export enum MessagingActionTypes {
  MESSAGING_TOAST_ADD = '[MESSAGING] TOAST ADD',
  MESSAGING_TOAST_REMOVE = '[MESSAGING] TOAST REMOVE',
  MESSAGING_REMOVE_ERRORS = '[MESSAGING] REMOVE ERRORS'
}

export type MessagingActions = ActionType<typeof actions>;

export interface MessagingState {
  toasts: ToastMessage[];
}

export interface ToastMessage {
  type: 'success' | 'error';
  message: string;
}

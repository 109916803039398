import { Observable } from 'rxjs';
import { Epic } from 'redux-observable';
import { Auth, API } from 'aws-amplify';
import axios from 'axios';
import moment from 'moment';
import { switchMap, filter } from 'rxjs/operators';
import { isOfType } from 'typesafe-actions';
import { FilesActionTypes } from './files.types';
import * as actions from './files.actions';
import * as messageActions from '../messaging/messaging.actions';

const getFilesEpic: Epic = (action$, state$) =>
  action$.pipe(
    filter(isOfType(FilesActionTypes.FILES_GET)),
    switchMap(() => {
      return new Observable(observer => {
        try {
          API.get('API_GATEWAY', '/files', {})
          .then(resp => {
            if (resp.error) {
              observer.next(actions.getFilesFailure(resp.error));
              observer.next(messageActions.toastsAdd({ type: 'error', message: resp.error }));
            } else {
              axios.get(resp.url)
              .then((fileData: any) => {
                if (fileData && fileData.data) {
                  const { user } = state$.value;
                  const mappedFiles = fileData.data.map((file: any) => {
                    let hasParent: boolean;
                    let access_level: number;
                    let parent_dir_id = file.parent_dir_id;
                    let hover_title = '';
                    let fileType: string;
                    const isShared = file.user_id !== user.creds.username;

                    if (file.content_type.includes('/')) {
                      fileType = file.content_type.split('/')[1];
                    } else {
                      fileType = file.content_type;
                    }
                    if (fileType === 'directory') {
                      fileType = 'folder';
                    }
                    if (isShared) {
                      hasParent = fileData.data.find((parentSearchFile: any) => parentSearchFile.file_id === file.parent_dir_id);
                      if (!hasParent) {
                        parent_dir_id = '.';
                      }
                    }
                    switch (file.access_status) {
                      case 'owned':
                        access_level = 3;
                        hover_title = 'You own this file';
                        break;
                      case 'editor':
                        access_level = 3;
                        hover_title = 'You have editor access rights to this this file.';
                        break;
                      case 'commenter':
                        access_level = 2;
                        hover_title = 'You have commenter access rights to this file.';
                        break;
                      case 'viewer':
                        access_level = 1;
                        hover_title = 'You have viewer access rights to this file.';
                        break;
                      default:
                        access_level = 3;
                        break;
                    }
                    return {
                      ...file,
                      displayName: `${file.name}${isShared ? ' (shared)' : ''}`,
                      access_level,
                      isShared,
                      hover_title,
                      parent_dir_id,
                      type: fileType,
                      last_updated: moment(file.last_updated).format('MMM D, YYYY')
                    };
                  });
                  observer.next(actions.getFilesSuccess(mappedFiles));
                } else {
                  observer.next(actions.getFilesFailure(resp.error));
                  observer.next(messageActions.toastsAdd({ type: 'error', message: resp.error }));
                }
              });
            }
          })
          .catch(err => observer.next(actions.getFilesFailure(err.message)));
        } catch (e) {
          observer.next(actions.getFilesFailure(e.message));
          observer.next(messageActions.toastsAdd({ type: 'error', message: e.message }));
          throw(e);
        }
      });
    })
  );

const uploadFilesEpic: Epic = action$ =>
  action$.pipe(
    filter(isOfType(FilesActionTypes.FILES_UPLOAD)),
    switchMap(() => {
      return new Observable(observer => {
        Auth.currentSession().then(session => {
          API.get('API_GATEWAY', '/files', {
            queryStringParameters: {
              cognito_access_token: session.getIdToken().getJwtToken()
            }
          })
          .then(resp => {
            if (resp.error) {
              observer.next(actions.uploadFilesFailure(resp.error));
              observer.next(messageActions.toastsAdd({ type: 'error', message: resp.error }));
            }
            observer.next(actions.uploadFilesSuccess(resp.files))
          })
          .catch(err => {
            observer.next(actions.uploadFilesFailure(err.message));
            observer.next(messageActions.toastsAdd({ type: 'error', message: err.message }));
          });
        });
      });
    })
  );

export const filesEpics = [
  getFilesEpic,
  uploadFilesEpic
];

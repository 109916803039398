import React, { useState } from 'react';
import { Button, Form, FormControl, Modal } from 'react-bootstrap';

export default ({ fileList, currentDirectory, show, toggleModal, submitForm }: any) => {
  const [directory, setDirectory]: any = useState('');
  const directoryList = fileList.filter((file: any) => {
    if (file.content_type === 'directory' && !file.checked) {
      return (
        file.parent_dir_id === currentDirectory.file_id ||
        file.file_id === currentDirectory.parent_dir_id
      );
    }
    return false;
  });
  return (
    <Modal show={show} onHide={() => toggleModal()}>
      <Modal.Header closeButton>
        <Modal.Title>Choose Destination to Copy</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="subscription">
            <Form.Label>Directory</Form.Label>
            <Form.Control
              as="select"
              size="lg"
              required
              value={directory}
              isInvalid={!directory}
              name="move-files-directory"
              onChange={(e: any) => setDirectory(e.target.value)}
            >
              <option>Select...</option>
              <option value={currentDirectory.file_id}>This folder ({currentDirectory.name || 'Root Folder'})</option>
              {currentDirectory.parent_dir_id === '.' && (
                <option value=".">Move to parent (root folder)</option>
              )}
              {directoryList.map((directory: any) => {
                return directory.file_id === currentDirectory.parent_dir_id ? (
                  <option value={directory.file_id} key={directory.file_id}>Move to parent ({directory.name})</option>
                ) : (
                  <option value={directory.file_id} key={directory.file_id}>{directory.name}</option>
                );
              })}
            </Form.Control>
            <FormControl.Feedback type="invalid">Directory is required.</FormControl.Feedback>
          </Form.Group>
          <Button
            type="submit"
            variant="primary"
            size="lg"
            block
            disabled={!directory}
            onClick={e => {
              e.preventDefault();
              if (directory) {
                const selectedDirectory = directory;
                setDirectory('');
                submitForm(selectedDirectory);
              }
            }}
          >
            Copy Files
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

import * as React from 'react';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { configureStore } from './redux/configureStore';
import { history } from './shared/util/history';
import Main from './main/Main.component';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';

class App extends React.Component {
  render() {
    return (
      <Provider store={configureStore()}>
        <Router history={history}>
          <Main />
        </Router>
      </Provider>
    );
  }
}

export default App;

import React from 'react';
import { Form } from 'react-bootstrap';
import { People } from 'react-bootstrap-icons';

import FileIconComponent from './FileIcon.component';

export default ({ currentSearchValue, currentDirectory, fileList, selectFile, viewFile, isViewingTrash, showFilesOfType }: any) => {
  return (
    <React.Fragment>
      { 
        fileList.length && fileList
          .filter((file: any) => {
            switch (showFilesOfType) {
              case 'owned':
                if (file.isShared) {
                  return false;
                }
                break;
              case 'shared':
                if (!file.isShared) {
                  return false;
                }
                break;
              case '*':
              default:
                break;
            }
            if (isViewingTrash) {
              if (file.is_in_trash && (file.parent_dir_id === currentDirectory || (currentDirectory === '.' && currentSearchValue))) {
                if (currentSearchValue) {
                  let match = false;
                  userLoop:
                  for (const prop in file) {
                    if (typeof file[prop] === 'string') {
                      if (file[prop].toLowerCase().includes(currentSearchValue.toLowerCase())) {
                        match = true;
                        break userLoop;
                      }
                    }
                  }
                  return match;
                }
                return true;
              }
              return false;
            } else if ((file.parent_dir_id === currentDirectory || (currentDirectory === '.' && currentSearchValue)) && !file.is_in_trash) {
              if (currentSearchValue) {
                let match = false;
                userLoop:
                for (const prop in file) {
                  if (typeof file[prop] === 'string') {
                    if (file[prop].toLowerCase().includes(currentSearchValue.toLowerCase())) {
                      match = true;
                      break userLoop;
                    }
                  }
                }
                return match;
              }
              return true;
            }
            return false;
          })
          .map((file: any) => {
            return (
              <div className="file-manager__file" key={file.file_id} id={file.file_id} title={file.hover_title}
                onClick={(e) => selectFile(e, file)} tabIndex={0}
                onDoubleClick={() => viewFile(file)}
                onKeyUp={(e) => selectFile(e, file)}
              >
                <Form.Group className="file-manager__checkbox">
                  <Form.Check type="checkbox" tabIndex={-1} checked={file.checked} readOnly />
                </Form.Group>
                <div className="file-manager__image-wrapper">
                  <FileIconComponent file={file} />
                </div>
                <span>{ file.displayName }</span>
                { file.isShared && 
                  <span className="file-manager__share-icon">
                    <People />
                  </span>
                }
              </div>
            );
          })
      }
    </React.Fragment>
  );
};

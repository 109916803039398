import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import Login from './login/Login.component';
import Home from './home/Home.component';
import NotFound from './notFound/NotFound.component';
import AppLoadingScreen from '../shared/components/appLoadingScreen/AppLoadingScreen.component';

import { userHasSession, userLogout } from '../redux/user/user.actions';
import { toastsRemove } from '../redux/messaging/messaging.actions';
import { Toast } from 'react-bootstrap';
import { ToastMessage } from '../redux/messaging/messaging.types';

import './Main.component.scss';
import GuestComponent from './guest/Guest.component';

const mapStateToProps = (state: any) => ({
  user: state.user,
  messaging: state.messaging
});

const mapDispatchToProps = {
  userHasSession,
  userLogout,
  toastsRemove
}

class Main extends Component<any> {
  componentDidMount() {
    this.props.userHasSession();
  }

  render() {
    const { isLoggedIn, isLoading, creds } = this.props.user;
    const accountType = isLoggedIn && creds.attributes ? creds.attributes['custom:account_type'] : 'guest';
    const { toasts } = this.props.messaging;
    return (
      <div id="main" className="main">
        { isLoading && <AppLoadingScreen /> }
        <Switch>
          <Route
            exact
            path="/"
            render={() => isLoggedIn
              ? <Redirect to={['admin', 'staff'].includes(accountType) ? "/home/account" : "home/file-manager" } />
              : <Redirect to="/login" /> }
          />
          <Route
            path="/login"
            render={() => !isLoggedIn
              ? <Login />
              : <Redirect to={['admin', 'staff'].includes(accountType) ? "/home/account" : "home/file-manager" } />
            }
          />
          <Route
            path="/home"
            render={() => isLoggedIn
              ? <Home user={this.props.user} messaging={this.props.messaging} logout={this.props.userLogout} />
              : <Redirect to="/login" />
            }
          />
          <Route
            path="/guest/:file_id"
            render={() => <GuestComponent />}
          />
          <Route render={() => <NotFound />} />
        </Switch>
        <div className="main__messaging">
            { toasts.map((toast: ToastMessage, index: number) => {
              return (
                <Toast delay={10000} autohide={accountType !== 'admin'} animation={true} key={`toast-message-${index}`} onClose={this.props.toastsRemove}>
                  <Toast.Header closeButton className={`custom-header-${toast.type}`}>
                    <strong className="mr-auto">{ toast.type }</strong>
                  </Toast.Header>
                  <Toast.Body>{ toast.message }</Toast.Body>
                </Toast>
              );
            })}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);

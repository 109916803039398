import React from 'react';
import { Alert, Button, Form, FormControl, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default ({ formErrors, user, submitAccountRecovery, updateField, recoveryEmail }: any) => {
  return (
    <React.Fragment>
      <h1>Account Recovery</h1>
      { user.successMessage && <Alert variant="success">{user.successMessage}</Alert> }
      { user.error && <Alert variant="danger">{user.error}</Alert> }
      <Link to="/login" className="login-component__back-button">
        &laquo; Back to Login
      </Link>
      <p>Enter the email associated with your account below, and Heirloom will send you verification code to use on the next page.</p>
      <Form onSubmit={submitAccountRecovery}>
        <Form.Group controlId="email">
          <Form.Label>Email Address</Form.Label>
          <Form.Control type="email" size="lg" isInvalid={!!formErrors.recoveryEmail} autoComplete="on"
            onChange={(e) => updateField('recoveryEmail', e.target.value)} disabled={user.isLoading} value={recoveryEmail} />
          <FormControl.Feedback type="invalid">{ formErrors.recoveryEmail }</FormControl.Feedback>
        </Form.Group>
        <Button type="submit" variant="primary" size="lg" block className="has-spinner">
          Submit
          { user.isLoading ?
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            /> : ''
          }
        </Button>
      </Form>
    </React.Fragment>
  );
}

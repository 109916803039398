import { Reducer } from 'redux';
import { MessagingActions, MessagingActionTypes, MessagingState } from './messaging.types';

const defaultMessagingState: MessagingState = {
  toasts: []
};

export const Messaging: Reducer<MessagingState, MessagingActions> = (state = defaultMessagingState, action) => {
  switch (action.type) {
    case MessagingActionTypes.MESSAGING_TOAST_ADD:
      return {
        ...state,
        toasts: [ ...state.toasts, action.payload ]
      }
    case MessagingActionTypes.MESSAGING_TOAST_REMOVE:
      const { toasts } = state;
      toasts.pop();
      return {
        ...state,
        toasts: [ ...toasts ]
      }
    default:
      return state;
  }
};

import React from 'react';
import { Redirect, Route, Switch, useRouteMatch, useHistory  } from 'react-router-dom';
import AccountHeader from '../accountHeader/AccountHeader.component';
import Account from './account/Account.component';
import FileManager from './fileManager/FileManager.component';

export default ({ user, logout }: any) => {
  const { url, path } = useRouteMatch();
  const history = useHistory();
  if (user && user.creds) {
    const { creds } = user;
    const accountType = creds.attributes && creds.attributes['custom:account_type'];
    return (
      <div className="home-component">
        <AccountHeader user={creds} logout={logout} />
        <Switch>
          <Route exact path={`${path}`}
            render={() => <Redirect to={ accountType === "admin" ? `${path}/account` : `${path}/file-manager`} /> }
          />
          <Route path={`${path}/account`} render={() => <Account />} />
          <Route path={`${path}/file-manager`} render={() => <FileManager />} />
        </Switch>
      </div>
    );
  }
  return <div />;
}

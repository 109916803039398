import React from 'react';
import { Spinner } from 'react-bootstrap';

import './AppLoadingScreen.component.scss';

export default () => {
  return (
    <div className="heirloom-loading-screen">
      <Spinner
          as="span"
          animation="border"
          role="status"
          aria-hidden="true"
          variant="primary"
        />
    </div>
  )
};

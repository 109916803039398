import React, { useState } from 'react';
import { Button, Form, FormControl, Modal, Spinner } from 'react-bootstrap';
import { Plus } from 'react-bootstrap-icons';

import { errorMessages } from '../../../shared/constants/messages';

export default ({ show, toggleModal, saveFile, files, selectedFile }: any) => {
  if (show) {
    let file: any;
    let fileCheckedError = false;
    if (selectedFile) {
      file = selectedFile;
    } else {
      const checkedFiles = files.filter((file: any) => file.checked);
      file = checkedFiles[0] || {};
      fileCheckedError = checkedFiles.length > 1 || checkedFiles < 1 ? true : false;
    }

    const [ name, setName ] = useState(file.name || '');
    const [ nameError, setNameError ] = useState('');
    const [ description, setDescription ] = useState(file.description || '');

    const submitForm = (e: any) => {
      e.preventDefault();
      if (!name) {
        setNameError(errorMessages.fileTitleRequired);
        return;
      }
      const fileObj: any = {
        content_type: file.content_type,
        file_id: file.file_id,
        parent_dir_id: file.parent_dir_id,
        description
      }
      if (file.access_status === 'owned' || file.access_status === 'editor') {
        fileObj.name = name;
      }
      saveFile(null, fileObj);
    };

    return (
      <Modal show={show} onHide={() => toggleModal()}>
        <Modal.Header closeButton>
          <Modal.Title>Rename File/Folder</Modal.Title>    
        </Modal.Header>
        <Modal.Body>
          {
            fileCheckedError
              ? <p>Please make sure one file is selected to rename it.</p>
              : <Form>
                  <Form.Group controlId="title">
                    <Form.Label>File Title</Form.Label>
                    <Form.Control
                      type="text" size="lg" required isInvalid={!!nameError}
                      onChange={e => setName(e.target.value)} value={name}
                      disabled={file.access_status !== 'owned' && file.access_status !== 'editor'}
                    />
                    <FormControl.Feedback type="invalid">{nameError}</FormControl.Feedback>
                  </Form.Group>
                  <Form.Group controlId="description">
                    <Form.Label>File Description</Form.Label>
                    <Form.Control
                      size="lg" required value={description}
                      onChange={e => setDescription(e.target.value)}
                      as="textarea" rows={3} 
                    />
                  </Form.Group>
                  <Button
                  type="submit" variant="primary" size="lg" block
                    disabled={false} onClick={submitForm}
                  >
                    <Plus size="25" />
                    Save File
                  </Button>
                </Form>
          }
        </Modal.Body>
      </Modal>
    );
  }
  return <div />;
};

import { of, Observable } from 'rxjs';
import { Epic } from 'redux-observable';
import { AnyAction } from 'redux';
import { Auth, API } from "aws-amplify";
import { switchMap, filter, tap, ignoreElements, withLatestFrom } from 'rxjs/operators';
import { action, isOfType } from 'typesafe-actions';
import { history } from '../../shared/util/history';
import { UserActionTypes } from './user.types';
import * as actions from './user.actions';
import * as messageActions from '../messaging/messaging.actions';
import { errorMessages, successMessages } from '../../shared/constants/messages';

const userLoginEpic: Epic = action$ =>
  action$.pipe(
    filter(isOfType(UserActionTypes.USER_LOGIN)),
    switchMap((action: AnyAction) => {
      return new Observable(observer => {
        const { username, password } = action.payload;
        Auth.signIn(username, password)
          .then((user: any) => {
            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
              observer.next(actions.userLoginFailure('', user));
              history.push("/login/change-password");
            } else if (user.error) {
              observer.next(actions.userLoginFailure(user.error));
            } else {
              observer.next(actions.userLoginSuccess(user));
            }
          })
          .catch(err => observer.next(actions.userLoginFailure(err.message)));
      });
    })
  );

const submitPasswordChangeEpic: Epic = (action$, state$) =>
  action$.pipe(
    filter(isOfType(UserActionTypes.SUBMIT_PASSWORD_CHANGE)),
    switchMap((action: AnyAction) => {
      return new Observable(observer => {
        const password = action.payload;
        const user = state$.value.user.creds;
        Auth.completeNewPassword(
          user,
          password
        ).then(() => {
          observer.next(actions.submitPasswordChangeSuccess());
          observer.next(messageActions.toastsAdd({ type: 'success', message: successMessages.passwordChangedSuccesfully }));
          Auth.signOut()
            .then(() => {
              history.push("/login");
            })
            .catch(() => {
              history.push("/login");
            });
        }).catch(err => {
          observer.next(actions.submitPasswordChangeFailure(err.message));
          observer.next(messageActions.toastsAdd({ type: 'error', message: err.message }));
        })
      });
    })
  );

const submitForgotPasswordEpic: Epic = action$ =>
  action$.pipe(
    filter(isOfType(UserActionTypes.SUBMIT_FORGOT_PASSWORD)),
    switchMap((action: AnyAction) => {
      const { password, verificationCode, email } = action.payload;
      return new Observable(observer => {
        Auth.forgotPasswordSubmit(
          email,
          verificationCode,
          password
        ).then(() => {
          observer.next(actions.submitPasswordChangeSuccess());
          observer.next(messageActions.toastsAdd({ type: 'success', message: successMessages.passwordChangedSuccesfully }));
          Auth.signOut()
            .then(() => {
              history.push("/login");
            })
            .catch(() => {
              history.push("/login");
            });
        }).catch(err => {
          observer.next(actions.submitPasswordChangeFailure(err.message));
          observer.next(messageActions.toastsAdd({ type: 'error', message: err.message }));
        })
      });
    })
  );


const userHasSessionEpic: Epic = action$ =>
  action$.pipe(
    filter(isOfType(UserActionTypes.USER_HAS_SESSION)),
    switchMap(() => {
      return new Observable(observer => {
        Auth.currentAuthenticatedUser({ bypassCache: true })
          .then(creds => observer.next(actions.userLoginSuccess(creds)))
          .catch(err => observer.next(actions.userLoginFailure(err.message)));
      });
    })
  );

const userSignupEpic: Epic = action$ =>
  action$.pipe(
    filter(isOfType(UserActionTypes.USER_SIGNUP)),
    switchMap((action: AnyAction) => {
      const { username, password } = action.payload;
      return new Observable(observer => {
        Auth.signUp({
          username,
          password,
          attributes: {
            email: username
          }
        })
        .then(creds =>  observer.next(actions.userSignupSuccess(creds)))
        .catch(err => observer.next(actions.userSignupFailure(err.message)));
      });
    })
  );

const userUpdateEpic: Epic = (action$, state$) =>
  action$.pipe(
    filter(isOfType(UserActionTypes.USER_UPDATE)),
    switchMap((action: AnyAction) => {
      return new Observable(observer => {
        API.patch('API_GATEWAY', '/create-user', {
          body: {
            payload: [
              {
                ...action.payload
              }
            ]
          }
        })
        .then((resp) => {
          if (resp.error) {
            observer.next(actions.userUpdateFailure(resp.error));
            observer.next(messageActions.toastsAdd({ type: 'error', message: resp.error }));
          } else {
            const { user } = state$.value;
            const accountType = user && user.creds && user.creds.attributes['custom:account_type'];
            const email = user && user.creds && user.creds.attributes.email;
            observer.next(actions.userUpdateSuccess());
            if (accountType !== 'customer') {
              observer.next(actions.userGetUserList(accountType === 'customer' ? email : '*'));
            }
            observer.next(messageActions.toastsAdd({ type: 'success', message: successMessages.userUpdated }));
          }
        })
        .catch(err => {
          observer.next(actions.userUpdateFailure(err.message));
          observer.next(messageActions.toastsAdd({ type: 'error', message: errorMessages.problemUpdatingUser }));
        });
      });
    })
  );

const userCreateEpic: Epic = action$ =>
  action$.pipe(
    filter(isOfType(UserActionTypes.USER_CREATE)),
    switchMap((action: AnyAction) => {
      return new Observable(observer => {
        API.post('API_GATEWAY', '/create-user', {
          body: {
            payload: [
              {
                ...action.payload
              }
            ]
          }
        })
        .then((resp) => {
          if (resp.error) {
            observer.next(actions.userCreateFailure(resp.error));
            observer.next(messageActions.toastsAdd({ type: 'error', message: resp.error }));
          } else {
            observer.next(actions.userCreateSuccess(resp.users));
            observer.next(messageActions.toastsAdd({ type: 'success', message: successMessages.userAdded }));
          }
        })
        .catch(err => {
          observer.next(actions.userCreateFailure(err.message));
          observer.next(messageActions.toastsAdd({ type: 'error', message: err.message }));
        });
      });
    })
  );

const userDeleteEpic: Epic = action$ =>
  action$.pipe(
    filter(isOfType(UserActionTypes.USER_DELETE)),
    switchMap((action: AnyAction) => {
      return new Observable(observer => {
        API.del('API_GATEWAY', '/create-user', {
          body: {
            payload: [
              {
                email: action.payload
              }
            ]
          }
        })
        .then(resp => {
          if (resp.error) {
            observer.next(actions.userCreateFailure(resp.error));
            observer.next(messageActions.toastsAdd({ type: 'error', message: resp.error }));
          } else {
            observer.next(actions.userDeleteSuccess(action.payload));
            observer.next(messageActions.toastsAdd({ type: 'success', message: successMessages.userDeleted }));
          }
        })
        .catch(e => {
          observer.next(actions.userDeleteFailure(errorMessages.problemDeletingUser));
          observer.next(messageActions.toastsAdd({ type: 'error', message: e.message }));
        });
      });
    })
  )

const getUserListEpic: Epic = action$ =>
  action$.pipe(
    filter(isOfType(UserActionTypes.USER_GET_USER_LIST)),
    switchMap((action: AnyAction) => {
      return new Observable(observer => {
        API.get('API_GATEWAY', '/get-user', {
          queryStringParameters: {
            email: action.payload,
          }
        })
        .then(creds => {
          if (creds.error) {
            observer.next(actions.userGetUserListFailure(creds.error))
          } else {
            observer.next(actions.userGetUserListSuccess(creds.users))
          }
        })
        .catch(() => observer.next(actions.userGetUserListFailure(errorMessages.problemRetrievingUsers)));
      });
    })
  );

const userRecoveryEpic: Epic = action$ =>
  action$.pipe(
    filter(isOfType(UserActionTypes.USER_ACCOUNT_RECOVER)),
    switchMap((action: AnyAction) => {
      return new Observable(observer => {
        Auth.forgotPassword(action.payload)
          .then(() => {
            history.push('/login/change-password');
            observer.next(actions.userRecoverySuccess(successMessages.recoverySuccess));
          })
          .catch(err => observer.next(actions.userRecoveryFailure(err.message)));
      });
    })
  );

const userLogoutEpic: Epic = action$ =>
  action$.pipe(
    filter(isOfType(UserActionTypes.USER_LOGOUT)),
    tap(() => Auth.signOut().then(() => history.push("/login/"))),
    ignoreElements()
  );

const userLoginSuccessEpic: Epic = action$ =>
  action$.pipe(
    filter(isOfType(UserActionTypes.USER_LOGIN_SUCCESS)),
    tap((action: AnyAction) => {
      history.push(['admin', 'staff'].includes(action.payload.attributes['custom:account_type'])
        ? "/home/account" : "/home/file-manager");
    }),
    ignoreElements()
  );

export const userEpics = [
  userLoginEpic,
  userHasSessionEpic,
  userSignupEpic,
  userCreateEpic,
  userDeleteEpic,
  getUserListEpic,
  userRecoveryEpic,
  userLogoutEpic,
  userLoginSuccessEpic,
  userUpdateEpic,
  submitPasswordChangeEpic,
  submitForgotPasswordEpic
];

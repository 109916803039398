import { Reducer } from 'redux';
import { MessagingActionTypes } from '../messaging/messaging.types';
import { FilesActions, FilesActionTypes, FilesState } from './files.types';

const defaultFilesState: FilesState = {
  err: null,
  isLoading: false,
  fileList: [],
  numChecked: 0,
  numFilesChecked: 0,
  numFoldersChecked: 0,
  selectedFiles: [],
  sortOptions: { column: 'name', direction: 'asc' }
};

export const Files: Reducer<FilesState, FilesActions> = (state = defaultFilesState, action) => {
  let fileList: any;
  let selectedFiles: any;
  switch (action.type) {
    case FilesActionTypes.FILES_GET:
      return {
        ...state,
        isLoading: true,
        fileList: []
      };
    case FilesActionTypes.FILES_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        fileList: action.payload.map((file: any) => {
          const checkedFile = state.selectedFiles.find((f: any) => f.file_id === file.file_id);
          let checked = false;
          if (checkedFile) {
            checked = checkedFile.checked;
          }
          return {
            ...file,
            checked: checked
          };
        })
      };
    case FilesActionTypes.FILES_GET_FAILURE:
      return {
        ...state,
        isLoading: false,
        fileList: [],
        error: action.payload
      };

    case FilesActionTypes.CHECK_FILE:
      fileList = [ ...state.fileList ];
      selectedFiles = [];
      let selectedFile: any;
      let { numChecked, numFilesChecked, numFoldersChecked } = state;
      fileList = fileList.map((file: any) => {
        if (file.file_id === action.payload) {
          selectedFile = file;
          file.checked = !file.checked;
        }
        if (file.checked) {
          selectedFiles.push(file);
        }
        return file;
      });
      numChecked = selectedFile.checked ? numChecked + 1 : numChecked - 1;
      if (selectedFile.content_type !== 'directory') {
        numFilesChecked = selectedFile.checked ? numFilesChecked + 1 : numFilesChecked - 1;
      }
      if (selectedFile.content_type === 'directory') {
        numFoldersChecked = selectedFile.checked ? numFoldersChecked + 1 : numFoldersChecked - 1;
      }
      return {
        ...state,
        isLoading: false,
        fileList,
        error: null,
        numChecked,
        numFilesChecked,
        numFoldersChecked,
        selectedFiles
      };
    case FilesActionTypes.SET_ALL_CHECKED:
      const parentDirectory = action.payload;
      const selectedFileList: any = [];
      let numberTotalChecked = 0;
      let numberFilesChecked = 0;
      let numberFoldersChecked = 0;
      fileList = state.fileList;
      fileList.forEach((file: any) => {
        if (file.parent_dir_id === parentDirectory) {
            file.checked = true;
          if (file.content_type !== 'directory') {
            numberFilesChecked+=1;
          } else {
            numberFoldersChecked+=1;
          }
          numberTotalChecked+=1;
          selectedFileList.push(file);
        }
      });
      return {
        ...state,
        isLoading: false,
        fileList,
        error: null,
        numChecked: numberTotalChecked,
        numFilesChecked: numberFilesChecked,
        numFoldersChecked: numberFoldersChecked,
        selectedFiles: selectedFileList
      };
    case FilesActionTypes.SET_ALL_UNCHECKED:
      fileList = state.fileList;
      fileList.forEach((file: any) => file.checked = false);
      return {
        ...state,
        isLoading: false,
        fileList,
        error: null,
        numChecked: 0,
        numFilesChecked: 0,
        numFoldersChecked: 0,
        selectedFiles: []
      };
    case FilesActionTypes.FILES_SORT:
      return {
        ...state,
        sortOptions: action.payload
      };

    case MessagingActionTypes.MESSAGING_REMOVE_ERRORS:
      return {
        ...state,
        err: null
      };
    default:
      return state;
  }
};

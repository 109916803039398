import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faFilePdf, faFileAudio, faFileVideo } from '@fortawesome/free-regular-svg-icons';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import folderIcon from '../../../../shared/images/folder-icon.svg';

export default ({ file }: any) => {
  const fileType = file.content_type.split('/')[1] || file.content_type;
  let Icon = <div />;
  switch (fileType) {
    case 'directory':
      Icon = <img src={folderIcon} className="file-manager__thumbnail" alt={file.name} />;
      break;
    case 'jpeg':
    case 'jpg':
    case 'png':
    case 'jfif':
    case 'gif':
      Icon = <LazyLoadImage
                alt={file.name}
                src={file.thumbnail_url || file.url}
             />;
      break;
    case 'mp4':
    case 'mov':
    case 'avi':
    case 'wmv':
      Icon = <FontAwesomeIcon icon={faFileVideo} className="fa-6x file-manager__thumbnail" />;
      break;
    case 'mp3':
    case 'mpeg':
      Icon = <FontAwesomeIcon icon={faFileAudio} className="fa-6x file-manager__thumbnail" />;
      break;
    case 'pdf':
      Icon = <FontAwesomeIcon icon={faFilePdf} className="fa-6x file-manager__thumbnail" />;
      break;
    default:
      Icon = <FontAwesomeIcon icon={faFileAlt} className="fa-6x file-manager__thumbnail" />;;
      break;
  }
  return Icon;
};

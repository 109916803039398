import React, { Component } from 'react';
import { API } from 'aws-amplify';
import { Accordion, Button, Card, Col, Form, FormControl, Modal, Spinner } from 'react-bootstrap';
import { CheckCircle, PlusCircle } from 'react-bootstrap-icons';
import { v4 as uuidv4 } from 'uuid';
import { successMessages } from '../../../shared/constants/messages';
import AppLoadingScreenComponent from '../../../shared/components/appLoadingScreen/AppLoadingScreen.component';
import { emailRegex } from '../../../shared/constants/regex';

export default class AddAccessModal extends Component<any> {
  accessTypes = [
    'viewer',
    'commenter',
    'editor'
  ];
  state: any;

  constructor(props: any) {
    super(props);
    this.state = {
      isLoading: false,
      userList: null,
      newUser: {
        email: '',
        given_name: '',
        family_name: '',
        access_status: 'viewer',
        value: [],
        isLoading: false,
        errors: {
          email: ''
        }
      }
    };
  }

  render() {
    const { show, toggleModal, updateUserAccessList } = this.props;
    return (
      <Modal show={show} className="access-files-modal"
        onHide={() => {
          this.setState({ userList: null });
          updateUserAccessList(null);
          toggleModal();
        }}>
        <Modal.Header closeButton>
          <Modal.Title>Share with people</Modal.Title>    
        </Modal.Header>
        <Modal.Body>
          { this.getModalBody() }
        </Modal.Body>
      </Modal>
    );
  }

  componentDidUpdate() {
    if (!this.state.userList && this.props.userList) {
      this.setState({
        userList: [ ...(this.props.userList ? this.props.userList : []) ]
      });
    }
  }

  getModalBody = () => {
    const { show, toggleModal, updateUserAccessList } = this.props;
    const { userList } = this.state;
    if (show) {
      return (
        <React.Fragment>
          { this.state.isLoading && <AppLoadingScreenComponent /> }
          <Form>
            <Accordion defaultActiveKey="1">
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="0">
                  Add another user
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <Form.Row>
                      <Col>
                        <Form.Group controlId="email">
                          <Form.Label>User Email</Form.Label>
                          <Form.Control
                            size="lg" required placeholder="User Email"
                            isInvalid={!!this.state.newUser.errors.email}
                            value={this.state.newUser.email} autoComplete="off" aria-autocomplete="none"
                            onChange={e => this.setState({ newUser: { ...this.state.newUser, email: e.target.value }})}
                            type="text"
                          />
                          <FormControl.Feedback type="invalid">{ this.state.newUser.errors.email }</FormControl.Feedback>
                        </Form.Group>
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Col sm="6">
                        <Form.Group controlId="given_name">
                          <Form.Label>First Name</Form.Label>
                          <Form.Control
                            size="lg" required placeholder="First Name" isInvalid={!!this.state.newUser.errors.given_name}
                            value={this.state.newUser.given_name} autoComplete="off" aria-autocomplete="none"
                            onChange={e => this.setState({ newUser: { ...this.state.newUser, given_name: e.target.value }})}
                            type="text"
                          />
                          <FormControl.Feedback type="invalid">{ this.state.newUser.errors.given_name }</FormControl.Feedback>
                        </Form.Group>
                      </Col>
                      <Col sm="6">
                        <Form.Group controlId="family_name">
                          <Form.Label>Last Name</Form.Label>
                          <Form.Control
                            size="lg" required placeholder="Last Name"
                            value={this.state.newUser.family_name} autoComplete="off" aria-autocomplete="none"
                            isInvalid={!!this.state.newUser.errors.family_name}
                            onChange={e => this.setState({ newUser: { ...this.state.newUser, family_name: e.target.value }})}
                            type="text"
                          />
                          <FormControl.Feedback type="invalid">{ this.state.newUser.errors.family_name }</FormControl.Feedback>
                        </Form.Group>
                      </Col>
                    </Form.Row>
                    <Form.Row className="justify-content-end">
                      <Button
                        onClick={e => {
                          this.addUser(e);
                        }} 
                      ><CheckCircle type="button"/> Add User</Button>
                    </Form.Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
            { userList.length > 0 &&
              <React.Fragment>
                { userList.map((user: any, userIndex: number) => {
                  return (
                    <Form.Row className="user-access-row" key={ user.user_id || user.temp_id }>
                      <Col md="8">
                        <label htmlFor={user.user_email}>{ user.user_email }</label>
                      </Col>
                      <Col md="4">
                        <Form.Control as="select" value={user.access_status}
                          onChange={e => this.handleAccessStatusChange(e.target.value, user, userIndex)}>
                          {this.accessTypes.map((accessType: string) => (
                            <option key={accessType} value={accessType} style={{textTransform: 'capitalize'}}>
                              {accessType === 'commenter' ? 'contributor' : accessType}
                            </option>
                          ))}
                          <option disabled>______________</option>
                          { user.access_status === 'varies' && <option value="varies" disabled>Varies</option> }
                          <option value="remove">Remove</option>
                        </Form.Control>
                      </Col>
                    </Form.Row>
                  );
                }) }
                <Button onClick={e => this.saveUserAccess(e)}><CheckCircle type="button"/> Save Changes</Button>
                <Button 
                  onClick={() => {
                    this.setState({ userList: null });
                    updateUserAccessList(null);
                    toggleModal();
                  }}
                  variant="secondary"
                  style={{marginLeft: '1rem'}}
                >
                  Cancel
                </Button>
              </React.Fragment>
            }
          </Form>
        </React.Fragment>
      );
    }
    return <div />;
  }

  addUser = (e: any) => {
    let emailError = '';
    let given_name_error = '';
    let family_name_error = '';
    e.preventDefault();
    if (!this.state.newUser.email) {
      emailError = 'Required.';
    } else if (!emailRegex.test(this.state.newUser.email)) {
      emailError = 'Enter a valid email.';
    }
    if (!this.state.newUser.given_name) {
      given_name_error = 'Required.';
    }
    if (!this.state.newUser.family_name) {
      family_name_error = 'Required';
    }
    if (!emailError && !given_name_error && !family_name_error) {
      const { userList } = this.state;
      if (!userList.find((user: any) => user.user_email === this.state.newUser.email)) {
        userList.push({
          user_email: this.state.newUser.email,
          given_name: this.state.newUser.given_name,
          family_name: this.state.newUser.family_name,
          access_status: 'viewer',
          temp_id: uuidv4()
        });
        this.setState({
          userList,
          newUser: {
            email: '',
            given_name: '',
            family_name: '',
            access_status: 'viewer',
            value: [],
            isLoading: false,
            errors: {
              email: ''
            }
          }
        });
        return;
      } else {
        alert('That email already has access!');
      }
    }
    this.setState({
      newUser: {
        ...this.state.newUser,
        errors: {
          email: emailError,
          family_name: family_name_error,
          given_name: given_name_error
        }
      }
    });
  }

  saveUserAccess = async (e: any) => {
    e.preventDefault();
    const { toggleModal } = this.props;
    try {
      this.setState({ isLoading: true });
      const { selectedFiles } = this.props;
      const userList = [ ...this.state.userList ];
      const newUserList: any[] = [];
      const saveList: any = [];
      userList.forEach((user: any) => {
        const { access_status, user_email, user_id } = user;
        if (!user_id) {
          newUserList.push(user);
        } else if (user.access_status !== 'varies') {
          selectedFiles.forEach((selectedFile: any) => {
            saveList.push({
              email: user_email,
              file_id: selectedFile.file_id,
              access_status
            });
          });
        }
      });
      if (newUserList.length) {
        for (let i = 0; i < newUserList.length; i++) {
          const newUser = newUserList[i];
          try {
            let savedUser: any;
            const userExistsBucket = await API.get('API_GATEWAY', '/get-user', {
              queryStringParameters: {
                email: newUser.user_email
              }
            });
            if (userExistsBucket.message !== 'Failed.' && userExistsBucket.users && userExistsBucket.users.length) {
              savedUser = userExistsBucket.users[0];
            }
            if (!savedUser) {
              const bucket = await API.post('API_GATEWAY', '/create-user', {
                body: {
                  payload: [{
                    email: newUser.user_email,
                    account_type: 'customer',
                    given_name: newUser.given_name,
                    family_name: newUser.family_name,
                    subscription: 'dependent'
                  }]
                }
              });
              if (bucket.error || bucket.message !== 'Success.') {
                this.props.toastsAdd({
                  type: 'error',
                  message: bucket.message
                });
              } else {
                savedUser = bucket.users[0]; 
              }
            }
            if (savedUser) {
              selectedFiles.forEach((selectedFile: any) => {
                saveList.push({
                  email: savedUser.email,
                  file_id: selectedFile.file_id,
                  access_status: newUser.access_status
                });
              });
            }
          } catch(e) {
            this.setState({ isLoading: false });
            throw(e);
          }
        }
      }
      if (saveList.length) {
        const bucket = await API.put('API_GATEWAY', '/user-interaction', {
          body: {
            payload: saveList
          }
        });
        if (bucket.error || bucket.message !== 'Success.') {
          this.props.toastsAdd({
            type: 'error',
            message: bucket.message
          });
        } else {
          this.props.toastsAdd({
            type: 'success',
            message: successMessages.userAccessSuccessfullyAdded
          });
        }
        this.setState({ isLoading: false });
      }
    } catch(e) {
      this.props.toastsAdd({
        type: 'error',
        message: e.message
      });
      this.setState({ isLoading: false });
      throw(e);
    }
    toggleModal();
  }

  handleAccessStatusChange = (access_status: any, user: any = null, userIndex: number = -1) => {
    const userList = [ ...this.state.userList ];
    if (access_status === 'remove') {
      this.removeUser(user, userIndex);
      return;
    }
    user.access_status = access_status;
    userList[userIndex] = user;
    this.setState({
      userList
    });
  }

  removeUser = async (user: any, userIndex: number) => {
    this.setState({ isLoading: true });
    let userList = [ ...this.state.userList ];
    if (userList[userIndex].user_id) {
      const { selectedFiles } = this.props;
      const payload: any[] = [];
      selectedFiles.forEach((selectedFile: any) => {
        payload.push({
          email: user.user_email,
          file_id: selectedFile.file_id
        });
      });
      try {
        const bucket = await API.del('API_GATEWAY', '/user-interaction', {
          body: {
            payload
          }
        });
        if (bucket.error || bucket.message !== 'Success.') {
          this.props.toastsAdd({
            type: 'error',
            message: bucket.message
          });
        } else {
          userList = userList.filter((u: any) => u.user_email !== user.user_email);
          this.setState({ userList });
        }
        this.setState({ isLoading: false });
      } catch (e) {
        this.props.toastsAdd({
          type: 'error',
          message: e.message
        });
        this.setState({ isLoading: false });
        throw(e);
      }
    } else {
      userList = userList.filter((u: any) => u.user_email !== user.user_email);
      this.setState({ userList, isLoading: false });
    }
  }

  handleFileSelect = (value: any, user: any = null, userIndex: number = -1) => {
    if (!user) {
      this.setState({
        newUser: {
          ...this.state.newUser,
          value
        }
      });
    } else {
      const { userList } = this.state;
      user.value = value;
      userList[userIndex] = user;
      this.props.updateUserAccessList(userList);
    }
  }
}

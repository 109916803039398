import React from 'react';
import { useParams } from "react-router-dom";
import AccountHeader from '../accountHeader/AccountHeader.component';
import FileViewer from '../fileViewer/FileViewer.component';
import './Guest.component.scss';

export default () => {
  const { file_id } = useParams<{ file_id: string }>();
  const decodedFileId = atob(file_id);
  const [ url, name, description, content_type ] = decodedFileId.split('{--}');

  return (
    <div className="home-component">
      <AccountHeader accountType={'guest'} />
      <div className="guest-wrapper">
        <h1>{ name }</h1>
        <FileViewer file={{ name, description, url, content_type }} />
      </div>
    </div>
  );
}
